/**
 * Component for our Return details sidebar that
 * lets users view and edit the details of a Return.
 *
 * @component
 */

import React, { Component } from "react";
import { DatePicker } from 'antd';
import axios from "axios";
import {
  Row,
  Col,
  Drawer,
  Button,
  Icon,
  Select,
  Badge,
  Tooltip,
  Divider,
  message
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import "../../../../styles/App.css";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import ReturnDetailsManualReturn from "./ReturnDetailsManualReturn";
import ReturnDetailsRefundButton from "./ReturnDetailsRefundButton";
import ReturnDetailsCustomer from "./ReturnDetailsCustomer";
import ReturnDetailsExchange from "./ReturnDetailsExchange";
import ReturnDetailsLabelAutomatic from "./ReturnDetailsLabelAutomatic";
import ReturnDetailsLabelManual from "./ReturnDetailsLabelManual";
import ReturnDetailsNoteStaff from "./ReturnDetailsNoteStaff";
import ReturnDetailsPhotoUpload from "./ReturnDetailsPhotoUpload";
import ReturnDetailsProduct from "./ReturnDetailsProduct";
import ReturnDetailsTimeline from "./ReturnDetailsTimeline";
import ReturnDetailsNoteCustomer from "./ReturnDetailsNoteCustomer";
import StripeWebhookDetails from "./StripeWebhookDetails";
import { trackFSEvent } from "../../../../helpers/fullstory";
const Option = Select.Option;

const pluralize = (word, count) => count === 1 ? word : `${word}s`;

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds 
 * @returns {string} Formatted time in days and hours
 */
const formatTime = (seconds) => {
  if(Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if(!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if(!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize('hour', hours)}`;
}

class ReturnDetails extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      stateOfReturn: null,
      notesKey: undefined,
      isLoading: false,
      drawerVisible: false,
      returnMethod: undefined,
      returnObject: null,
      loading: true,
      productsOfReturn: null,
      labelService: null,
      shipDate: null,
      labelStatus: null,
      storeAddress: [],
      selectedStoreAddress: null,
      useGiftApiForStoreCredit: undefined,
      useDiscountCodeForStoreCredit: undefined,
      width: "50%",
      discountCodeStatusTracking: true,
    };
  }

  onChange = (date, dateString) => {
    this.setState({
      shipDate: dateString
    })
  }

  handleChange(value) {
    this.setState({
      stateOfReturn: value,
    });
  }

  handleGetReturn = async () => {
    try {
      const response = await axios.post(`/api/returns/${this.props.returnId}`);
      if(response.data.labelService && response.data.labelService.length > 0 ){
        this.setState({
          labelService: response.data.labelService[0].labelService,
          // fetch {useGiftApiForStoreCredit, useDiscountCodeForStoreCredit} from returnSettings
          useGiftApiForStoreCredit: response.data.labelService[0].useGiftApiForStoreCredit,
          useDiscountCodeForStoreCredit: response.data.labelService[0].useDiscountCodeForStoreCredit,
        })
      }
      this.setState({
        returnObject: response.data.returnObject,
        productsOfReturn: [...response.data.productsOfReturn],
        loading: false,
        labelStatus: response.data.returnObject.labelStatus
      });
    } catch (err) {
      return message.error(
        "Error getting return details. Please try again.",
        5
      );
    }

  };

  componentDidMount = async () => {
    this.handleGetReturn();
    if(this.context.featureMultipleAddress) {
      this.getStoreAddresses();
    }
    this.adjustDrawerWidth();
  };

  adjustDrawerWidth = () => {
    try {
      const innerWidthOfWindow = window.innerWidth;
      if(innerWidthOfWindow) {
        if (innerWidthOfWindow >= 992) {
          this.setState({
            width: "50%"
          })
        } else if(innerWidthOfWindow >= 768) {
          this.setState({
            width: "70%"
          })
        } else if(innerWidthOfWindow >= 576) {
          this.setState({
            width: "80%"
          })
        } else {
          this.setState({
            width: "100%"
          })
        }
      }
    } catch(err) {
      console.log("Error while adjustig drawer width: ", err)
    }
  }
  getStoreAddresses = async() => {
    try {
      const response = await axios.get("/api/returnSettings/multipleAddress");
      const storeAddress = response.data.getStoreAddress;
      const filterAddress = storeAddress.find(e => { return e.defaultActive === true });
      this.setState({
        storeAddress,
        selectedStoreAddress: filterAddress
      });

    } catch (err) {
      console.log("Error, unable to get store address", err);
    }
  }

  handleOnChange = (id) => {
    const filterStoreAddress = this.state.storeAddress.find(e => { return e.id === id});

    this.setState({
      selectedStoreAddress: filterStoreAddress,
    })

  }

  handleLoadingState = (value) => {
    this.setState({
      isLoading: value,
    });
  }

  handleUpdate = async () => {
    trackFSEvent("Update Return Order", { 
      feature: "Return Settings",
      updateStatus: this.state.stateOfReturn
        ? this.state.stateOfReturn
        : this.state.returnObject.returnStatus,
      refundStatus: true,
      discountCodeStatus: this.state.returnObject?.StoreCredits[0] && this.state.returnObject?.StoreCredits[0].type === 'discountCode' ? true : false,
      customer: this.state.returnObject.customerFirstName + " " + this.state.returnObject.customerLastName,
      customerEmail: this.state.returnObject.customerEmail,
      resolution: this.state.returnObject.resolution,
      returnMethod: this.returnMethodDefaultValue(),
    });
    this.setState({
      isLoading: true,
    });
    try {
      const response = await axios.post("/api/returns/update", {
        stateOfReturn: this.state.stateOfReturn
          ? this.state.stateOfReturn
          : this.state.returnObject.returnStatus,
        returnId: this.props.returnId,
        shippingMethod: this.state.returnMethod,
        shipDate: this.state.shipDate ? this.state.shipDate : moment().format('YYYY-MM-DD'),
        selectedStoreAddress: this.state.selectedStoreAddress ? this.state.selectedStoreAddress : null 

      });
      if (response.data.err && response.data.err.status === 'autoRefundError' && response.data.err.error) {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.err.error)
      }

      if (response.data.status === "error") {
        this.setState({
          isLoading: false,
        });
        return message.error(response.data.error, 4);
      }
      this.handleGetReturn();
      this.setState({
        isLoading: false,
      });
      if (response.data.labelError) {
        return message.error("Error while attempting to create a new label. Try again.", 2);
      }

      if (response.data.exchangeFailed?.error) {
        return message.error("Error while attempting to create an exchange order. Try again.", 2);
      }

      return message.success("Status updated successfully.", 4);
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      if(err.status === "autoRefundError") {
        return message.error(err.error,5);
      } else {
        return message.error(
          "Error updating status return. Please try again.",
          5
        );
      }
    }
  }

  returnMethodDefaultValue = () => {
    if (this.state.returnObject.shippingMethod === "methodLabelCreation") {
      return "Pre-Paid label (automatic)";
    }
    if (this.state.returnObject.shippingMethod === "methodLabelManual") {
      return "Pre-Paid label (manual)";
    }
    if (this.state.returnObject.shippingMethod === "methodCustomerPaidLabel") {
      return "Customer paid return label";
    }
    if (this.state.returnObject.shippingMethod === "methodCustomerChoice") {
      return "Customer responsible for Return Shipping";
    }
    if (this.state.returnObject.shippingMethod === "methodInStore") {
      return "In-Store return";
    }
    return "";
  };

  render() {
    let productsObject = [];
    let orderIncludesExchangeItem = false;
    if (!isEmpty(this.state.productsOfReturn)) {
      this.state.productsOfReturn.map((item) => {
        if (
          item.ReturnId === this.props.returnId &&
          item.wantsToReturn === true
        ) {
          productsObject.push(item);
          if (item.exchangeItemVariantId != null) {
            orderIncludesExchangeItem = true;
          }
        }
      });
    }

    const restockingFeeType = this.state.returnObject && this.state.returnObject.restockingFeeType;
    // calculate refund total
    let refundCurrency;
    let refundTotal = 0.0;
    let refundExists =
      this.state.returnObject &&
      this.state.returnObject.RefundTransactions &&
      this.state.returnObject.RefundTransactions.length > 0;
    let refundError = false;
    let refundErrorMessage;

    if (refundExists) {
      this.state.returnObject.RefundTransactions.map((transaction) => {
        refundCurrency = transaction.currency;
        refundTotal = refundTotal + parseFloat(transaction.amount);

        const errorStates = ["error", "failure"];
        const errorExists = errorStates.includes(transaction.status);
        if (errorExists) {
          refundError = true;
          const { message, gateway } = transaction;
          if (refundErrorMessage) {
            refundErrorMessage = `${refundErrorMessage}\n(${gateway}) ${message}`;
          } else {
            refundErrorMessage = `Check the refund manually in Shopify for details. Shopify says:\n(${gateway}) ${message}`;
          }
        }
      });
    }

    // calculate store credit
    let storeCreditCurrency;
    let storeCreditTotal = 0.0;
    let storeCreditId;
    let storeCreditType;
    let storeCreditExists =
      this.state.returnObject &&
      this.state.returnObject.StoreCredits &&
      this.state.returnObject.StoreCredits.length > 0;

    if (storeCreditExists) {
      this.state.returnObject.StoreCredits.map((storeCredit) => {
        storeCreditCurrency = storeCredit.currency;
        storeCreditTotal = storeCreditTotal + parseFloat(storeCredit.amount);
        storeCreditId = storeCredit.externalId;
        storeCreditType = storeCredit.type;
      });
    }
    return (
      <React.Fragment>
        {this.state.loading ? null : (
          <Drawer
            title={"Details of return #" + this.state.returnObject.returnNumber}
            width={this.state.width}
            placement="right"
            visible={this.props.drawerVisible}
            onClose={() => this.props.hideDrawer()}
          >
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Current status</strong>
              </Col>
              <Col span={15}>
                <Select
                  defaultValue={this.state.returnObject.returnStatus}
                  onChange={this.handleChange.bind(this)}
                >
                  <Option value="Pending">Pending</Option>
                  <Option value="Approved">Approved</Option>
                  <Option value="Shipped">Shipped</Option>
                  <Option value="In Transit">In Transit</Option>
                  <Option value="Received">Received</Option>
                  <Option value="Resolved">Resolved</Option>
                  <Option value="Rejected">Rejected</Option>
                </Select>
              </Col>
            </Row>
            {this.state.returnObject.platform === "manual" ? null : (
              <React.Fragment>
                <Row
                  type="flex"
                  justify="start"
                  align="top"
                  className="editReturnDetailsRow"
                >
                  <Col span={9}>
                    <strong>Order details</strong>
                  </Col>
                  <Col span={15}>
                    <a
                      href={
                        this.context.storeShopifyUrl +
                        "/admin/orders/" +
                        this.state.returnObject.orderId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to order on Shopify
                    </a>
                  </Col>
                </Row>

                {this.state.returnObject &&
                  this.state.returnObject.Refunds &&
                  this.state.returnObject.Refunds[0] ? (
                    <React.Fragment>
                      <Row
                        type="flex"
                        justify="start"
                        align="top"
                        className="editReturnDetailsRow"
                      >
                        <Col span={9}>
                          <strong>Refund</strong>
                        </Col>
                        <Col span={15}>
                          {
                            <React.Fragment>
                              {refundError ? (
                                <React.Fragment>
                                  <Badge
                                    status="error"
                                    text={
                                      <span>Refund issued. Potential issue.</span>
                                    }
                                  />{" "}
                                  <Tooltip
                                    title={refundErrorMessage}
                                    placement="bottom"
                                  >
                                    <Button size="small">View Details</Button>
                                  </Tooltip>
                                </React.Fragment>
                              ) : (
                                <Badge
                                  status="success"
                                  text={
                                    <span>
                                    Refund issued ({refundCurrency}{" "}
                                      {refundTotal})
                                    </span>
                                  }
                                />
                              )}
                            </React.Fragment>
                          }
                        </Col>
                      </Row>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row
                        type="flex"
                        justify="start"
                        align="top"
                        className="editReturnDetailsRow"
                      >
                        <Col span={9}>
                          <strong>Refund this order?</strong>
                        </Col>
                        <Col span={15}>
                          <React.Fragment>
                            <ReturnDetailsRefundButton
                              returnId={this.state.returnObject.id}
                              returnObject={this.state.returnObject}
                              showStoreCreditDialog={false}
                              showRefundDialog={true}
                              handleGetReturn={this.handleGetReturn}
                            ></ReturnDetailsRefundButton>{" "}
                            <a
                              href={
                                this.context.storeShopifyUrl +
                              "/admin/orders/" +
                              this.state.returnObject.orderId +
                              "/refund"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                            Refund on Shopify
                            </a>
                          </React.Fragment>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                {
                  !(this.context.featureStoreCredit && (this.context.featureGiftApiForStoreCredit || this.context.featureDiscountCodeForStoreCredit)) && 
                  (this.state.useGiftApiForStoreCredit
                  || this.state.useDiscountCodeForStoreCredit)  ?
                    storeCreditExists ? (
                      <React.Fragment>
                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}>
                            <strong>Store-Credit</strong>
                          </Col>
                          <Col span={15}>
                            <Badge
                              status="success"
                              text={
                                <span>Store-Credit issued & sent to customer</span>
                              }
                            />
                          </Col>
                        </Row>
                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}></Col>
                          <Col span={15}>
                            <Button
                              size="small"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                this.context.storeShopifyUrl +
                                "/admin/gift_cards/" +
                                storeCreditId
                              }
                            >
                              View Gift-Card on Shopify
                            </Button>{" "}
                            {storeCreditTotal} {storeCreditCurrency}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}>
                            <strong>Issue store credit?</strong>
                          </Col>
                          <Col span={15}>
                            {this.context.featureStoreCredit && (
                              <React.Fragment>
                                <ReturnDetailsRefundButton
                                  returnId={this.state.returnObject.id}
                                  returnObject={this.state.returnObject}
                                  showStoreCreditDialog={true}
                                  showRefundDialog={false}
                                  handleGetReturn={this.handleGetReturn}
                                ></ReturnDetailsRefundButton>{" "}
                              </React.Fragment>
                            )}
                            <a
                              href={
                                this.context.storeShopifyUrl +
                                "/admin/gift_cards/new"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Send a gift-card on Shopify
                            </a>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    :
                    storeCreditExists ? (
                      <React.Fragment>

                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}>
                            <strong>Store Credit</strong>
                          </Col>
                          <Col span={15}>
                            <Badge
                              status="success"
                              text={
                                <span>{storeCreditType === "discountCode" ? "Discount Code issued & sent to customer" : "Gift Card issued & sent to customer"}</span>
                              }
                            />
                          </Col>
                        </Row>
                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}></Col>
                          {
                            storeCreditType === "discountCode" ?
                              <Col span={15}>
                                <Button
                                  size="small"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    this.context.storeShopifyUrl +
                                    "/admin/discounts"
                                  }
                                >
                                  View Discount on Shopify
                                </Button>{" "}
                                {storeCreditTotal} {storeCreditCurrency}
                              </Col>
                              :
                              <Col span={15}>
                                <Button
                                  size="small"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    this.context.storeShopifyUrl +
                                    "/admin/gift_cards/" +
                                    storeCreditId
                                  }
                                >
                                  View Gift-Card on Shopify
                                </Button>{" "}
                                {storeCreditTotal} {storeCreditCurrency}
                              </Col>
                          }
                        </Row>
                      </React.Fragment>
                    ) :  
                      <React.Fragment>
                        <Row
                          type="flex"
                          justify="start"
                          align="top"
                          className="editReturnDetailsRow"
                        >
                          <Col span={9}>
                            <strong>Store Credit</strong>
                          </Col>
                          <Col span={15} style={{ lineHeight: `2rem`}}>
                            <div>
                              {(this.context.featureStoreCredit && this.context.featureGiftApiForStoreCredit && this.state.useGiftApiForStoreCredit) && (                            
                                <React.Fragment>
                                  <ReturnDetailsRefundButton
                                    returnId={this.state.returnObject.id}
                                    returnObject={this.state.returnObject}
                                    showStoreCreditDialog={true}
                                    showRefundDialog={false}
                                    handleGetReturn={this.handleGetReturn}
                                    storeCreditType="GIFT_CARD"
                                  ></ReturnDetailsRefundButton>{" "}
                                </React.Fragment>
                              )}
                              <a
                                href={
                                  this.context.storeShopifyUrl +
                                  "/admin/gift_cards/new"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Send a gift-card on Shopify
                              </a>{<br></br>}
                              {(this.context.featureStoreCredit && this.context.featureDiscountCodeForStoreCredit && this.state.useDiscountCodeForStoreCredit) && (
                                <React.Fragment>
                                  <ReturnDetailsRefundButton
                                    returnId={this.state.returnObject.id}
                                    returnObject={this.state.returnObject}
                                    showStoreCreditDialog={true}
                                    showRefundDialog={false}
                                    handleGetReturn={this.handleGetReturn}
                                    storeCreditType="DISCOUNT_CODE"
                                  ></ReturnDetailsRefundButton>{" "}
                                </React.Fragment>
                              )}
                              <a
                                href={
                                  this.context.storeShopifyUrl +
                                  "/admin/discounts"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Send a discount-code on Shopify
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                }


                {this.state.returnObject.restockingFeeValue ? (
                  <Row
                    type="flex"
                    justify="start"
                    align="top"
                    className="editReturnDetailsRow"
                  >
                    <Col span={9}></Col>
                    <Col span={15}>
                      <Icon type="minus-circle" /> Restocking fee:{" "}
                      {`${parseFloat(
                        this.state.returnObject.restockingFeeValue
                      ).toFixed(2)} ${restockingFeeType === "fixed"? this.state.returnObject.currency : "%"}`}
                    </Col>
                  </Row>
                ) : null}
                {(!this.state.returnObject.ruleMethodCustomerPaidLabel && this.state.returnObject.labelFeeValue) ? (
                  <Row
                    type="flex"
                    justify="start"
                    align="top"
                    className="editReturnDetailsRow"
                  >
                    <Col span={9}></Col>
                    <Col span={15}>
                      <Icon type="minus-circle" /> Label fee:{" "}
                      {`${parseFloat(
                        this.state.returnObject.labelFeeValue
                      ).toFixed(2)} ${this.state.returnObject.currency}`}
                    </Col>
                  </Row>
                ) : null}
                {this.state.labelService === "shipstation" && this.state.labelStatus !== 'SUCCESS'? 
                  <Row
                    type="flex"
                    justify="start"
                    align="top"
                    className="editReturnDetailsRow"
                  >
                    <Col span={9}>
                      <strong>Shipping Date(ShipStation)</strong>
                    </Col>
                    <Col span={15}>
                      <DatePicker   
                        onChange={this.onChange}
                        defaultValue = {moment()}
                        disabledDate={(current) => {
                          return moment().add(-1, 'days')  >= current ||
                          moment().add(7, 'days')  <= current;
                        }}
                      />
                    </Col>
                  </Row> : null
                }
              </React.Fragment>
            )}
            <ReturnDetailsExchange
              returnObject={this.state.returnObject}
              orderIncludesExchangeItem={orderIncludesExchangeItem}
              storeShopifyUrl={this.context.storeShopifyUrl}
              handleGetReturn={this.handleGetReturn}
              featureExchangeOrders={this.context.featureExchangeOrders}
            />

            <ReturnDetailsLabelAutomatic
              returnObject={this.state.returnObject}
              handleGetReturn={this.handleGetReturn}
            />

            {(this.state.returnObject.labelStatus === "ERROR" &&  this.state.returnObject.suggestAddressValidator) &&
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                  <Icon type="bulb" className="bulbIcon" theme="filled"/>
                  <strong>Rich Returns Recommendation</strong>
                </Col>
                <Col span={15}>
                  <span>Address Validator Plus can eliminate 95% of label issues. Try it <a href="https://apps.shopify.com/address-validator" target="_blank">here</a></span>
                </Col>
              </Row>
            }
            { (this.context.featureStripePayment) && 
              <React.Fragment>
                {this.state.returnObject.orderStatus === 'Paid' &&
                  <StripeWebhookDetails
                    headingText = {"Order Payment Status"}
                    text = {'Order payment successful'}
                    returnObject = {this.state.returnObject}
                  />
                }
                {this.state.returnObject.labelFeeStatus === 'Paid' &&
                  <StripeWebhookDetails
                    headingText = {"Lable Fee Status"}
                    text = {'Label Fee has been paid'}
                    returnObject = {this.state.returnObject}
                  />
                }
                {this.state.returnObject.paymentErrorMessage &&
                  <StripeWebhookDetails
                    paymentError = {true}
                    returnObject = {this.state.returnObject}
                  />
                }
              </React.Fragment>
            }

            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Customer</strong>
              </Col>
              <Col span={15}>
                {this.state.returnObject.customerFirstName +
                  " " +
                  this.state.returnObject.customerLastName}
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>E-Mail</strong>
              </Col>
              <Col span={15}>
                {this.state.returnObject.customerEmail}
                <ReturnDetailsCustomer
                  buttonType="link"
                  handleGetReturn={this.handleGetReturn}
                  returnObject={this.state.returnObject}
                />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                {this.state.returnObject.orderName ? (
                  <React.Fragment>
                    <strong>Order number / name</strong>{" "}
                    <Tooltip
                      placement="bottom"
                      title="Order-Number is Shopify's internal and increasing number for orders. Order-Name can include things like pre- or postfixes. For the majority of Shopify Stores the Order-Number equals the Order-Name."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <strong>Order number</strong>
                )}
              </Col>
              <Col span={15}>
                {this.state.returnObject.orderName ? (
                  <span>
                    #{this.state.returnObject.orderNumber} /{" "}
                    {this.state.returnObject.orderName}
                  </span>
                ) : (
                  <span>#{this.state.returnObject.orderNumber}</span>
                )}
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Ordered at</strong>
              </Col>
              <Col span={15}>
                {moment(this.state.returnObject.orderDate).format(
                  "MMM DD, YYYY HH:mm a"
                )}
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Return requested at</strong>
              </Col>
              <Col span={15}>
                {moment(this.state.returnObject.requestDate).format(
                  "MMM DD, YYYY HH:mm a"
                )}
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Time-to-Return</strong>{" "}
                <Tooltip
                  placement="bottom"
                  title="Time-to-return is the time interval (in days) between the time of the order and the return request, in other words, this metric shows how long it took your customer to decide to return the product after buying it from your store."
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </Col>
              <Col span={15}>
                {formatTime(moment(this.state.returnObject.requestDate).diff(this.state.returnObject.orderDate, 'seconds'))}
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Resolution</strong>
              </Col>
              <Col span={15}>
                {this.state.returnObject.resolution &&
                  this.state.returnObject.resolution.includes("refundToCustom")
                  ? this.state.returnObject.resolutionLabel
                  : this.props.resolutionOptions[
                    this.state.returnObject.resolution
                  ]}
              </Col>
            </Row>
            { this.context.featureMultipleAddress && 
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                  <strong>Select Store Address</strong>{" "}
                  <Tooltip
                    placement="bottom"
                    title="Select you required Store for correct return-label creation. Return-label Is created on he basis of selected store address."
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Select
                    style={{ width: "90%" }}
                    placeholder={"Select Store Address"}
                    value={this.state.selectedStoreAddress && this.state.selectedStoreAddress.id}
                    onChange={(e)=> this.handleOnChange(e)}
                  >
                    {
                      this.state.storeAddress &&
                      this.state.storeAddress.length > 0 &&
                      this.state.storeAddress.map((address) => (
                        <Option
                          value={address.id}
                          key={address.id}
                        >
                          {address.locationName}
                        </Option>
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            }
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Return method</strong>{" "}
                <Tooltip
                  placement="bottom"
                  title="To change the Return-Method to an automatic Prepaid-Label, make sure that the Status of the Return is 'Approved'. Otherwise a label will not be issued."
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </Col>
              <Col span={15}>
                <Select
                  style={{ width: "70%" }}
                  defaultValue={this.returnMethodDefaultValue()}
                  onChange={(e) => this.setState({ returnMethod: e })}
                >
                  <Option value="methodLabelCreation">
                    Pre-Paid Return Label (automatic)
                  </Option>
                  <Option value="methodLabelManual">
                    Pre-Paid Return Label (manual)
                  </Option>
                  <Option value="methodCustomerPaidLabel">
                    Customer is responsible for label
                  </Option>
                  <Option value="methodInStore">In-Store Return</Option>
                  <Option value="methodCustomerChoice">
                    Customer is responsible for shipping
                  </Option>
                </Select>
              </Col>
            </Row>
            <ReturnDetailsLabelManual
              returnObject={this.state.returnObject}
              handleGetReturn={this.handleGetReturn}
              returnId={this.props.returnId}
            />

            <ReturnDetailsPhotoUpload
              featureCustomerPhotoUpload={
                this.context.featureCustomerPhotoUpload
              }
              returnObject={this.state.returnObject}
              closeImageDialog={this.closeImageDialog}
              customerPhotoUrl1={this.state.returnObject.customerPhotoUrl1}
              customerPhotoUrl2={this.state.returnObject.customerPhotoUrl2}
              customerPhotoUrl3={this.state.returnObject.customerPhotoUrl3}
              photoUploads={this.state.returnObject.photoUploads}
            />
            <ReturnDetailsNoteStaff
              returnObject={this.state.returnObject}
              returnId={this.props.returnId}
              staffNotes={this.state.returnObject.staffNotes}
              handleGetReturn={this.handleGetReturn}
            />
            <ReturnDetailsTimeline returnId={this.props.returnId} />

            <Divider>Products</Divider>

            <ReturnDetailsProduct
              productsObject={productsObject}
              featureExchangeOrders={this.context.featureExchangeOrders}
              handleGetReturn={this.handleGetReturn}
              returnObject={this.state.returnObject}
              resolutionOptions={this.props.resolutionOptions}
              handleUpdate={this.handleUpdate}
              handleLoadingState ={this.handleLoadingState}
              updateData={ this.props.updateData.bind(this) }
              setVisibilityOfResetSearchButton={this.props.setVisibilityOfResetSearchButton.bind(this)}         
            />
            <ReturnDetailsNoteCustomer returnObject={this.state.returnObject} />

            <div
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "left",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <Button
                onClick={this.handleUpdate.bind(this)}
                loading={this.state.isLoading}
                type="primary"
              >
                Update
              </Button>
              <Button
                style={{
                  marginLeft: 8,
                }}
                onClick={() => this.props.hideDrawer()}
              >
                Cancel
              </Button>
              {this.context.featureMultichannel &&
                this.state.returnObject.platform === "manual" ? (
                  <ReturnDetailsManualReturn
                    handleGetReturn={this.handleGetReturn}
                    returnObject={this.state.returnObject}
                  />
                ) : null}
            </div>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

export default ReturnDetails;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Button } from "antd";
import { Helmet } from "react-helmet";
import axios from "axios";
import UseIntercomHook from "./intercomHook";
// screens
import Navigation from "./navigation/Navigation";
import UpgradeNotification from "../../components/UpgradeNotification";
import { SubscriptionContext } from "../../contexts/SubscriptionContext";
import DashboardRouter from "../../routes/Dashboard";
import { initFullStory } from "../../helpers/fullstory";

//styles
import "../../styles/App.css";

const { Header, Content, Sider } = Layout;
let isLocalhost = false;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverLatestUpdatesButton: false,
    };
  }

  static contextType = SubscriptionContext;

  async componentDidMount() {
    try {
      const response = await axios.get("/api/integrations/intercom/getUser");

      if (response) {

        const userData = response.data.userData


        const userStoreInfo = response.data;

        const email = userStoreInfo.userData.shopifyProfileEmail ? userStoreInfo.userData.shopifyProfileEmail : userStoreInfo.userData.customer_email;
        const user_id = userStoreInfo.userData.domain;
        const store_created_at = userStoreInfo.userData.storeCreatedAt;
        const shopify_domain = userStoreInfo.userData.domain ? userStoreInfo.userData.domain : userStoreInfo.userData.myshopify_domain;
        const shopify_plan = userStoreInfo.userData.plan_name;
        const plan_name = userStoreInfo.subscription ? userStoreInfo.subscription.shopifyPlanName : '';
        const name = userData.name;
        initFullStory({
          domain: userStoreInfo.userData.domain,
          displayName: name,
          email,
          status_str: "active",// 'active' or 'uninstalled'
          shopId_str: user_id, // unique identifier of shop (ie 'xxxxx' in xxxxx.myshopify.com or xxxxx.mybigcommerce.com, unique URL)
          platform_str: "shopify",// platform name (ie 'shopify', 'bigcommerce')
          planName_str: shopify_plan,// platform plan name (ie 'shopify_plus')
          appHubId_str: "rich-returns",// business unit name (ie 'conversionbear')
          appName_str: "Rich Returns",// app name (ie 'checkoutbear')
          //new user property more properties
          shopify_plan_str: shopify_plan,
          plan_types: plan_name,
          country: userStoreInfo.userData,
          days_active: userStoreInfo.trackingInfo.daysActive,
          return_number: userStoreInfo.trackingInfo.totalReturns,
          trial_period: userStoreInfo.trackingInfo.trialPeriod,
          trial_days: userStoreInfo.trackingInfo.trialDays,
        });
        //intercom
        this.props.boot({ customAttributes: { email, user_id, store_created_at, shopify_domain, shopify_plan, plan_name, apphub_id: 'rich-returns', alignment: 'left', horizontal_padding: "20", } });


        const url = window.location.href;
        const urlObject = new URL(url);
        const urlHostname = urlObject.host;

        // this.createIntercomUser(userData);
        // this.createFullStoryUser(userData)


        if (this.context.first_use) {
          this.createIntercomUser(userData);
          this.createFullStoryUser(userData);
        }
        if (urlHostname.includes("localhost")) {
          isLocalhost = true;
        }
      }
    } catch (err) {
      console.log("Error getting the user data", err);
    }
  }

  createIntercomUser = async (userData) => {
    try {
      const user_id = userData.domain ? userData.domain : userData.myshopify_domain
      if (user_id) {
        await axios.post(`/api/messenger/intercom/user/create`, { user_id, userData });
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  createFullStoryUser = async (userData) => {
    try {
      const uid = userData.domain ? userData.domain : userData.myshopify_domain
      if (uid) {
        await axios.post(`/api/messenger/fullstory/user/create`, { uid, userData });
      }
    } catch (error) {
      console.log("Failed to hit update status of full story while uninstall", error);
    }
  }

  helmatRender = () => {
    const urlObject = new URL(window.location.href);
    const urlpathname = urlObject.pathname;

    return (
      <Helmet>
        <script type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              'userId': '${this.context?.storeShopifyUrl}',
              'userProject': 'RichReturns',
              'pagePath': '${urlpathname}',
              'pageuserRRLevelTitle': '${(this.context?.inTrial === true) ? true : this.context?.planName}',
              'userShopifyLevel': '${this.context?.planTitle}'
            });
          `}
        </script>
        <script type="text/javascript">
          {
            (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T5X6QQD5')
          }
        </script>
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T5X6QQD5" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
    );
  }

  handleMouseEnter = () => {
    this.setState({ hoverLatestUpdatesButton: true });
  };

  handleMouseLeave = () => {
    this.setState({ hoverLatestUpdatesButton: false });
  };

  render() {
    const { hoverLatestUpdatesButton } = this.state;
    const buttonStyle = {
      background: hoverLatestUpdatesButton ? '#6FA5A5' : '#619191', // Change color on hover
      borderColor: hoverLatestUpdatesButton ? '#6FA5A5' : '#619191',
    };
    return (
      <React.Fragment>
        {this.context?.companyId && this.helmatRender()}
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-129175672-1"
          ></script>
          <script type="text/javascript">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
      
            gtag('config', 'UA-129175672-1', { 'anonymize_ip': true });
          `}
          </script>
          {!isLocalhost && this.context && this.context.companyId ? (
            <script type="text/javascript">
              {`
                    window._refinerQueue = window._refinerQueue || []; function _refiner(){_refinerQueue.push(arguments);} _refiner('setProject', '69f4fcc0-c1f8-11eb-9def-716bf873030e'); (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://js.refiner.io/v001/client.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)})();
                    
                    _refiner('identifyUser', {
                      id: ${this.context.companyId},
                      companyId: ${this.context.companyId}
                    });
                    `}
            </script>
          ) : null}
        </Helmet>
        <UpgradeNotification />

        <Layout>
          <Sider theme="light" breakpoint="md" collapsedWidth="0">
            <Link to="/dashboard">
              <div className="logo" />
            </Link>
            <Navigation />
          </Sider>
          <Layout>
            <Header style={{ background: "#fff", padding: 0 }}>
              <span style={{ float: "right", marginRight: 40 }}>
                <span> </span>
                <Button
                  type="primary"
                  href="https://www.richreturns.io/updates"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  style={buttonStyle}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                >
                  See Latest Updates
                </Button>
                <span> </span>
                <Button
                  type="primary"
                  href="https://support.apphub.com/en/collections/3507240-rich-returns"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  Help-Center
                </Button>
                <span> </span>
                <Button
                  type="primary"
                  href="mailto:hello@richcommerce.co"
                  size="small"
                >
                  Contact Us
                </Button>
              </span>
            </Header>
            <Content
              style={{
                margin: "10px 8px",
                padding: 24,
                background: "#fff",
                minHeight: 600,
              }}
            >
              <DashboardRouter />
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

export default UseIntercomHook(Dashboard);
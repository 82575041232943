/**
 * Component for our main Return-Dashboard that displays
 * all of the user's Returns.
 * 
 * Also includes the initial onboarding screen that is
 * shown on first use of the App.
 * 
 * @component
 */

import React from "react";
import { Row, Col, Input, Button, Dropdown, Icon, Menu, Steps, Typography, Divider, Tooltip, message, DatePicker, Checkbox, Tag } from "antd";
import axios from "axios";
import { getAppInfo } from "../../../helpers/appInfo";
import ReturnsTable from "./components/ReturnsTable";
import { SubscriptionContext } from "../../../contexts/SubscriptionContext";
import ManualReturn from "./components/ManualReturn";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VideoPopup from "../../../components/VideoPopup";
import "./ReturnStyles.css"
import { Helmet } from "react-helmet";
import moment from "moment";
import { trackFSEvent } from "../../../helpers/fullstory";
import LoginModal from "./components/LoginModal";
import { Popconfirm } from "../../../../node_modules/antd/lib/index";

const { Search } = Input;
const { Step } = Steps;
const { Title } = Typography;
const { Text } = Typography;
const { RangePicker } = DatePicker;

class Returns extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      returnsCount: undefined,
      returns: [],
      productsOfReturns: [],
      returnsEmpty: true,
      resolutionLabel: undefined,
      buttonResetSearchVisible: false,
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      searchValue: "",
      isLoading: false,
      pageCurrent: 1,
      appUrl: null,
      isProductionApp: null,
      env: null,
      showFilters: false,
      startDate: "",
      endDate: "",
      sortedBy: { key: "createdAt", direction: "DESC" },
      visibleStatus: false,
      visibleReslotions: false,
      visibleReturnReasons: false,
      rangeApplied: false,
      allowedStatus: {
        All:"All",
        Pending:"Pending",
        Approved:"Approved",
        Shipped:"Shipped",
        InTransit:"In Transit",
        Received:"Received",
        Resolved:"Resolved",
        Rejected:"Rejected"
      },
      filters: {},
      tags: {},
      tagsArray: [],
      searchTerm: "",
      showModal: false,
      hasSubmittedIndustry: false,
      loadingRecreateLabel: false,
    };
  }


  async componentDidMount() {
    const response = await axios.post("/api/returns", {
      selectedStatus: "All",
      pageCurrent: 1,
      pageSize: 10,
    });
    if (response.data?.status === "error") {
      return message.error("Error loading your returns. Please try again.");
    }

    this.setState({
      ...response.data,
      returnsEmpty: false,
    });
    if (this.context.companyId) {
      this.checkIndustrySubmitted();
    }
    const info = await getAppInfo();
    this.getResolutions()
    await this.getReturnReasons()
    this.setState({
      appUrl: info.appUrl,
      isProductionApp: info.isProductionApp,
      env: info.env
    })
  }

  updateData(object) {
    this.setState({
      ...object,
      buttonResetSearchVisible: false,
      returnsCount: 1,
    });
  }

  setVisibilityOfResetSearchButton = (visibility) => {
    this.setState({
      buttonResetSearchVisible: visibility,
    });
  }

  resetSearchResults = async (pageCurrent) => {
    const response = await axios.post("/api/returns", {
      filters: this.state.filters,
      pageCurrent,
      pageSize: 10,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortedBy: this.state.sortedBy,
      rangeApplied: this.state.rangeApplied,
      searchTerm: "" 
    });
    if (response.data?.status === "error") {
      return message.error("Error loading your returns. Please try again.");
    }

    this.setState({
      ...response.data,
      returnsEmpty: false,
      buttonResetSearchVisible: false,
      searchValue: "",
      pageCurrent: pageCurrent

    });
  }

  searchReturns = async (searchTerm) => {
    this.setState({
      isLoading: true
    })
    if (searchTerm == null || searchTerm === "") {
      this.setState({
        isLoading: false
      })
      return message.error("Your search term cannot be empty.", 5);
    }

    const cleanedSearchTerm = searchTerm.trim();

    try {
      const response = await axios.post("/api/returns", {
        filters: this.state.filters,
        pageCurrent: 1,
        pageSize: 10,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        sortedBy: this.state.sortedBy,
        rangeApplied: this.state.rangeApplied,
        searchTerm: cleanedSearchTerm
      });

      if (response.data?.status === "error") {
        this.setState({
          isLoading: false
        })
        return message.error("Error searching returns. Please try again.", 5);
      }

      const responseData = response.data;
      const returns = responseData.returns;
      const returnsEmpty = returns.length === 0 ? true : false;

      this.setState({
        ...response.data,
        returnsEmpty,
        isLoading: false,
        searchTerm: cleanedSearchTerm
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        searchTerm: cleanedSearchTerm,
      })
      return message.error("Error searching returns. Please try again.", 5);
    }
  }

  setSelectedRows(selectedRowKeys, selectedRows) {
    this.setState({
      // selectedRowKeys,
      selectedRows,
    });
  }

  bulkStatusChange = async (e) => {
    const newStatus = e.key;
    const selectedRows = this.state.selectedRows;

    this.setState({
      loading: true,
    });

    try {
      const response = await axios.post("/api/returns/bulkStatus", {
        newStatus,
        selectedRows,
      });

      if (response.data?.status === "error") {
        this.setState({
          loading: false,
        });
        return message.error("Error bulk updating status. Please try again.", 5);
      }

      this.setState({
        loading: false,
        ...response.data,
        buttonResetSearchVisible: true,
      });
      await this.resetSearchResults(this.state.pageCurrent);

      return message.success("Status updated successfully.", 5);

    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error("Error bulk updating status. Please try again.", 5);
    }
  }

  tabSelected = async () => {
    const cleanedSearchTerm = this.state.searchValue && this.state.searchValue !== "" ? this.state.searchValue.trim():null;

    const response = await axios.post("/api/returns", {
      filters: this.state.filters,
      pageCurrent: 1,
      pageSize: 10,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortedBy: this.state.sortedBy,
      rangeApplied: this.state.rangeApplied,
      searchTerm: cleanedSearchTerm
    });
    if (response.data?.status === "error") {
      return message.error("Error loading your returns. Please try again.");
    }

    this.setState({
      ...response.data,
      returnsEmpty: false,
      buttonResetSearchVisible: false,
      pageCurrent: 1
    });

  }

  getResolutions = () => {
    let resolutionOptions = {
      refundToOriginal: "Refund",
      refundToExchange: "Exchange",
      refundToCredit: "Store credit",
      multiple: "Multiple",
    };
    if (this.context.featureStoreWideExchange) resolutionOptions = {...resolutionOptions,storeWideExchange:"Store-Wide Exchange"}
    if (this.context.featureAdvancedExchanges) resolutionOptions = {...resolutionOptions,advancedExchange: "Advanced Exchange"}
    resolutionOptions = {...resolutionOptions, refundToCustom1: "Custom 1", refundToCustom2: "Custom 2", refundToCustom3: "Custom 3",}
    this.setState({
      resolution: resolutionOptions,
    })
  }

  getReturnReasons = async () => {
    try {
      const response = await axios.get("/api/returnSettings/returnReasons?isDashboard=true");
      if (response?.data) {
        const returnReasons = {}
        response.data.forEach(reason => {
          returnReasons[reason.reason] = reason.reason;
        })
        this.setState({
          returnReasons,
        });
      }
    } catch (err) {
      return message.error("Something went wrong please try again", 5);
    }
  };

  onChange = (dates, dateStrings) => {
    this.setState({
      startDate: dateStrings[0] && moment(dateStrings[0]),
      endDate: dateStrings[1] && moment(dateStrings[1]),
      rangeApplied: true,
    },() => {
      this.tabSelected()
    });
    trackFSEvent("Using filters in dashboard", {
      feature: `Date`,
    });
  }

  handleSorter = (key) => {
    const oldDirection = this.state.sortedBy.key === key ? this.state.sortedBy.direction : "ASC";
    const newDirection = oldDirection === "ASC" ? "DESC" : "ASC";
    this.setState({
      sortedBy: {key, direction: newDirection},
    }, () => this.tabSelected())
  }

  paginatePages = async (paginationObject) => {
    const pageCurrent = paginationObject.current;
    const { pageSize } = paginationObject;
    const cleanedSearchTerm = this.state.searchValue && this.state.searchValue !== "" ? this.state.searchValue.trim():null;

    const response = await axios.post("/api/returns", {
      filters: this.state.filters,
      pageCurrent,
      pageSize,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sortedBy: this.state.sortedBy,
      rangeApplied: this.state.rangeApplied,
      searchTerm: cleanedSearchTerm
    });
    if (response.data?.status === "error") {
      return message.error("Error loading your returns. Please try again.");
    }

    this.setState({
      ...response.data,
      returnsEmpty: false,
      pageCurrent
    });
  }

  handleSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value
    }, () => {
      if (this.state.searchValue === "") 
        this.resetSearchResults(this.state.pageCurrent)
    })
  }

  handleVisibleChange = (target,flag) => {
    this.setState({ [target]: flag });
  };

  handleMenuClick = (e, target, tag) => {
    trackFSEvent("Using filters in dashboard", {
      feature: e.target.name,
    });
    let tagsArray = [];
    if (!e.target.checked) {
      tag = tag ? tag : this.state[target === "status" ? "allowedStatus" : target][e.target.name]
      tagsArray = this.state.tagsArray.filter(item => item !== tag)
      this.setState({
        tagsArray
      })
    }
    this.setState({
      filters:{
        ...this.state.filters,
        [target]: {
          ...this.state.filters[target],
          [e.target.name]: e.target.checked
        }
      },
      tags: {
        ...this.state.tags,
        [target]: {
          ...this.state.tags[target],
          [e.target.name]: e.target.checked,
        }
      },
    }, () => {
      if (e.target.checked) {
        this.handleTags()
      }
      this.tabSelected();
    })
  };

  handleTags = () => {
    const { status, resolution, returnReasons } = this.state.tags

    const filteredStatus = status && Object.entries(status)?.filter(([key, value]) => value === true)?.map(([key]) => this.state.allowedStatus[key]) || [];
    const resolutionsArray = resolution && Object.entries(resolution)?.filter(([key, value]) => value === true)?.map(([key]) => this.state.resolution[key]) || [];
    const returnReasonsArray = returnReasons && Object.entries(returnReasons)?.filter(([key, value]) => value === true)?.map(([key]) => this.state.returnReasons[key]) || [];
    this.setState({
      tagsArray : [...filteredStatus, ...resolutionsArray, ...returnReasonsArray],
    })
  }

  resetFilters = (target) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [target]: {}
      },
      tags: {
        ...this.state.tags,
        [target]: {}
      }
    }, () => {
      this.handleTags()
      this.tabSelected()
    })
  }

  findKeyByValue = (obj, targetValue) => {
    for (const key in obj) {
      if (obj[key] === targetValue) {
        return key;
      }
    }
    return null;
  }

  removeTag = (e, tag) => {
    let tagKey = this.findKeyByValue(this.state.allowedStatus, tag)
    tagKey = tagKey ? tagKey : this.findKeyByValue(this.state.resolution, tag)
    tagKey = tagKey ? tagKey : this.findKeyByValue(this.state.returnReasons, tag)
    const targetObject = {
      target: {
        name: tagKey,
        checked: false
      }
    }
    if (Object.keys(this.state.allowedStatus)?.includes(tagKey)) {
      this.handleMenuClick(targetObject, "status", tag)
    } else if (Object.keys(this.state.resolution)?.includes(tagKey)) {
      this.handleMenuClick(targetObject, "resolution", tag)
    } else if (Object.keys(this.state.returnReasons)?.includes(tagKey)) {
      this.handleMenuClick(targetObject, "returnReasons", tag)
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  checkIndustrySubmitted = async () => {
    try {
      const companyId = this.context.companyId;
      const response = await axios.post("/api/returns/getIndustryStatus", { companyId });

      const { storeIndustry } = response.data;

      // Set the state based on storeIndustry value
      this.setState({
        hasSubmittedIndustry: storeIndustry !== null && storeIndustry !== '',
        showModal: storeIndustry === null || storeIndustry === '',
      });
    } catch (err) {
      return message.error("Unable to fetch industry submission status", 5);
    }
  };

  recreatePrepaidLabel = async (returnObjects) => {
    try {
      this.setState({
        loadingRecreateLabel: true,
      });
      const objectsWithLabelError = returnObjects.filter((returnObject) => returnObject.labelStatus==="ERROR");
      
      if(objectsWithLabelError.length === 0) {
        this.setState({
          loadingRecreateLabel: false,
        });
        return message.error("No returns selected with creating label errors.", 4);
      }

      const response = await axios.post("/api/returns/recreateMultipleLabels", {
        returnObjects: objectsWithLabelError,
      });

      if (response.data.status === "error") {
        this.setState({
          loadingRecreateLabel: false,
        });

        return message.error("Error while attempting to create a new label. Try again.", 4);
      }
      await this.resetSearchResults(this.state.pageCurrent);

      this.setState({
        loadingRecreateLabel: false,
      });

      return message.success("Label created successfully.", 5);

    } catch(err) {
      this.setState({
        loadingRecreateLabel: false,
      });
      return message.error("Error while attempting to create a new label. Try again.", 2);
    }
  }

  render() {
    const { loading, selectedRows, appUrl, showModal, loadingRecreateLabel } = this.state;
    const hasSelected = selectedRows.length > 0;
    const menu = (
      <Menu onClick={this.bulkStatusChange.bind(this)}>
        <Menu.Item key="Shipped">
          Shipped
        </Menu.Item>
        <Menu.Item key="In Transit">
          In Transit
        </Menu.Item>
        <Menu.Item key="Received">
          Received
        </Menu.Item>
        <Menu.Item key="Resolved">
          Resolved
        </Menu.Item>
        <Menu.Item key="Rejected">
          Rejected
        </Menu.Item>
      </Menu>
    );
    const menuStatus = (
      <Menu>
        {Object.entries(this.state.allowedStatus)?.filter(([key, _]) => key !== "All")?.map(([key, value]) => {
          return  <Menu.Item key={key}>
            <Checkbox style={{width:"100%"}} name={key} checked={this.state?.filters?.status && this.state?.filters?.status[key]}   onChange={(e)=>this.handleMenuClick(e,"status")}>{value}</Checkbox>
          </Menu.Item>
        })}
        <Menu.Item key="buttons" className="test">
          <Row type="flex" justify="center" >
            <Button type="link" onClick={()=>this.resetFilters("status")}>Reset</Button>
          </Row>
        </Menu.Item>
      </Menu>
    );
    const menuResolutions = (
      <Menu>
        {this.state?.resolution && Object.entries(this.state.resolution)?.filter(([key, _]) => key !== "All")?.map(([key, value]) => {
          return  <Menu.Item key={key}>
            <Checkbox style={{width:"100%"}} name={key} checked={this.state?.filters?.resolution && this.state?.filters?.resolution[key]}  onChange={(e)=>this.handleMenuClick(e,"resolution")}>{value}</Checkbox>
          </Menu.Item>
        })}
        <Menu.Item key="buttons" className="test">
          <Row type="flex" justify="center" >
            <Button type="link" onClick={()=>this.resetFilters("resolution")}>Reset</Button>
          </Row>
        </Menu.Item>
      </Menu>
    );
    const menuReturnReasons = (
      <Menu>
        {this.state?.returnReasons && Object.entries(this.state.returnReasons)?.filter(([key, _]) => key !== "All")?.map(([key, value]) => {
          return  <Menu.Item key={key}>
            <Checkbox style={{width:"100%"}} name={key} checked={this.state?.filters?.returnReasons && this.state?.filters?.returnReasons[key]}  onChange={(e)=>this.handleMenuClick(e,"returnReasons")}>{value}</Checkbox>
          </Menu.Item>
        })}
        <Menu.Item key="buttons" className="test">
          <Row type="flex" justify="center" >
            <Button type="link" onClick={()=>this.resetFilters("returnReasons")}>Reset</Button>
          </Row>
        </Menu.Item>
      </Menu>
    );
    const portalLink = appUrl + `/return/new?c=${this.context.identifier}`;

    return (

      this.context.first_use
        ? (
          <React.Fragment>
            {this.state.isProductionApp && this.state.env != "development" &&
              <Helmet>
                {/* <!-- Global site tag (gtag.js) - Google Ads: 368894066 --> */}
                <script 
                  async 
                  src="https://www.googletagmanager.com/gtag/js?id=AW-368894066"
                ></script>
                <script type="text/javascript">
                  {` 
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments)}
                  gtag('js', new Date());

                  gtag('config', 'AW-368894066');
                  gtag('event', 'conversion', {'send_to': 'AW-368894066/uigcCMf4x9UDEPLA868B'});

                  `}
                </script>
                {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                <script 
                  async 
                  src="https://www.googletagmanager.com/gtag/js?id=UA-129175672-1"
                ></script>
                <script type="text/javascript">
                  {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments)}
                  gtag('js', new Date());

                  gtag('config', 'UA-129175672-1', {
                  'page_title' : 'install_screen'
                  'page_path' : '/app-installed'
                  });
                  `}
                </script>
              </Helmet>
            }
            <Row type="flex" justify="center" align="middle" className="onboarding">
              <Col span={18}>
                <Row type="flex" justify="center" align="top">
                  <Title>Welcome to RichReturns 👋</Title>
                </Row>
                <Row type="flex" justify="center" align="top">
                  <Title level={3}>Your Return-Portal is ready to go.</Title>
                </Row>

                <Row type="flex" justify="center" align="top">
                  <Button href={`/return/new?c=${this.context.identifier}`} target="_blank" rel="noopener noreferrer" type="primary">
                    View your (unstyled) Return-Portal
                  </Button>
                  <Divider type="vertical" />
                  <CopyToClipboard text={portalLink}>
                    <Button type="primary">Copy link to your Return-Portal</Button>
                  </CopyToClipboard>
                  <Input style={{ display: "none" }} type="text" value={portalLink} id="portalLink" />
                </Row>

                <Row type="flex" justify="center" align="top">
                  <Col span={12}>
                    <Divider />
                  </Col>
                </Row>

                <Steps direction="vertical" style={{ marginTop: 0 }}>
                  <Step
                    title={<React.Fragment>Test a return <VideoPopup videoId="dClCHuZ3_7E" /></React.Fragment>}
                    description="Create a test-order in your Shopify store with your own email address, valid shipping address and a product. Use it to run a test return through the portal."
                    status="process"
                  />
                  <Step
                    title={<React.Fragment>Upload a logo. <VideoPopup videoId="XIQA6x4F_2c" /></React.Fragment>}
                    description="Customize the Return-Portal to your brand under the 'Brand Settings' tab on the left side."
                    status="process"
                  />
                  <Step
                    title={<React.Fragment>Connect to your store <VideoPopup videoId="QkXVEoj3RzY" /></React.Fragment>}
                    description="Click the 'Copy link' button above and paste the link anywhere on your store, e.g. footer, navigation, returns policy page. Now customers can start making returns."
                    status="process"
                  />
                  <Step
                    title={<React.Fragment>Prepaid labels? <VideoPopup videoId="u_VowInci08" /></React.Fragment>}
                    description="We support sending Prepaid-Labels to your customers through EasyPost, Shippo and ShipStation. Go to 'Return Settings / General' tab on the left to configure this."
                    status="process"
                    icon={<Icon type="smile" />}
                  />
                  <Step
                    title="Tutorials"
                    description="We have step-by-step video tutorials for you on every feature under the 'Tutorials & FAQ' tab."
                    icon={<Icon type="video-camera" />}
                    status="process"
                  />
                  <Step
                    title="Important"
                    description="Set a business rule for yourself and your staff to NEVER delete products AND variants that are still returnable. We recommend at least 360 days. The best strategy is to simply make them unavailable on all storefronts."
                    icon={<Icon type="warning" style={{ color: "red" }} />}
                    status="process"
                  />
                </Steps>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col span={24} style={{ textAlign: "center" }}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/XIQA6x4F_2c?showinfo=0&autoplay=0&rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="top">
              <Col span={12}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="top">
              <Text>By using our services you agree to our <a href="https://www.richcommerce.co/legal/terms-of-service/" target="_blank">Terms & Conditions</a>, <a href="https://www.richcommerce.co/legal/acceptable-use-policy/" target="_blank">Acceptable Use</a>, <a href="https://www.richcommerce.co/legal/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://www.richcommerce.co/legal/privacy/data-processing-addendum" target="_blank">Data Processing Addendum</a>.</Text>
            </Row>
          </React.Fragment>
        )
        : (
          <React.Fragment>
            <Row type="flex" justify="space-between" align="stretch" >
              <Col span={16} style={{ textAlign: "left" }}  align="stretch" >
                {
                  this.context.featureMultichannel
                    ? <ManualReturn updateData={this.updateData.bind(this)} setVisibilityOfResetSearchButton={this.setVisibilityOfResetSearchButton.bind(this)} />
                    : null
                }
                { hasSelected ?
                  <React.Fragment>
                    <Row type="flex" style={{height:"100%", alignItems:"flex-end"}} >
                      <Dropdown overlay={menu} disabled={!hasSelected} className="bulkStatusChanger" >
                        <Button loading={loading}>
                          Change status <Icon type="down" />
                        </Button>
                      </Dropdown>
                      <div style={{marginLeft:"10px"}}>
                        <Tooltip
                          placement="right"
                          title="This will attempt to generate a new label for the selected returns with creating label errors and send the Approved-Email including the label to the customer"
                        >
                          <Popconfirm
                            title="Create a new label and send it to the customer?"
                            onConfirm={()=>{this.recreatePrepaidLabel(selectedRows)}}
                            okText="Yes"
                            cancelText="Cancel"
                          >
                            <Button loading={loadingRecreateLabel}>
                              Recreate labels
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                      </div>
                    </Row>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <Dropdown overlay={menuStatus} onVisibleChange={(flag)=>this.handleVisibleChange("visibleStatus", flag)} visible={this.state.visibleStatus}>
                      <Button className="filterButtons">
                        Status <Icon type="down" />
                      </Button>
                    </Dropdown>
                    <Dropdown overlay={menuResolutions} onVisibleChange={(flag)=>this.handleVisibleChange("visibleReslotions", flag)} visible={this.state.visibleReslotions}>
                      <Button className="filterButtons">
                        Resolution <Icon type="down" />
                      </Button>
                    </Dropdown>
                    <Dropdown overlay={menuReturnReasons} onVisibleChange={(flag)=>this.handleVisibleChange("visibleReturnReasons", flag)} visible={this.state.visibleReturnReasons}>
                      <Button className="filterButtons">
                        Return Reasons <Icon type="down" />
                      </Button>
                    </Dropdown>
    
                    <RangePicker
                      className="dashboardRangePicker"
                      defaultValue={[this.state.startDate, this.state.endDate]}
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "day").startOf("day"),
                          moment().subtract(1, "day").endOf("day"),
                        ],
                        "Last Week": [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")],
                        "This Month": [moment().startOf("month"), moment().endOf("month")],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "Last Quarter": [
                          moment().subtract(3, "months").startOf("quarter"),
                          moment().subtract(3, "months").endOf("quarter"),
                        ],
                      }}
                      onChange={this.onChange}
                      onCalendarChange={(dateArray) => {
                        const endDateSelected = dateArray[1] != null;
                        if (endDateSelected) {
                          return this.setState({
                            date: undefined,
                          });
                        }
    
                        const startDateSelected = dateArray[0];
                        if (startDateSelected) {
                          this.setState({
                            date: dateArray[0],
                          });
                        }
                      }}
                    />
                  </React.Fragment>
                }
              </Col>
              <Col span={8}>
                <Tooltip
                  title="Search by: Customer name, email, order number, (RMA) return number, tracking number, order name'"
                >
                  <Icon
                    style={{
                      position: "relative",
                      right: "19px",
                      top: "27px"
                    }}
                    type="info-circle"
                  />
                </Tooltip>
                {this.state.searchValue ? <Icon className="cross-icon" style={{ color: "red" }} type="close" onClick={() => this.resetSearchResults(this.state.pageCurrent)} /> : ""}
                <Search
                  placeholder="Search for a return..."
                  enterButton="Search"
                  value={this.state.searchValue}
                  onChange={this.handleSearchChange}
                  loading={this.state.isLoading}
                  onSearch={searchTerm => this.searchReturns(searchTerm)}
                />
              </Col>
            </Row>
            {!hasSelected &&
              <Row style={{marginLeft: "10px"}}>
                {this.state.tagsArray?.length > 0 &&  this.state.tagsArray.map(tag => {
                  return <Tag closable onClose={(e) => this.removeTag(e, tag)} visible={true} key={tag} style={{marginTop:"20px"}}>
                    {tag}
                  </Tag>
                })}
              </Row>
            }
            <React.Fragment>
              <Row type="flex" justify="center" align="top">
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              {showModal && 
                <LoginModal
                  visible={showModal}
                  onOk={this.handleModalSubmit}
                  onClose={this.closeModal}
                />
              }
              <ReturnsTable
                searchValue={this.state.searchValue}
                searchTerm={this.searchReturns}
                returns={this.state.returns}
                returnsCount={this.state.returnsCount}
                productsOfReturns={this.state.productsOfReturns}
                selectedRowKeys={this.state.selectedRowKeys}
                resetSearchResults={this.resetSearchResults.bind(this)}
                paginatePages={this.paginatePages.bind(this)}
                setSelectedRows={this.setSelectedRows.bind(this)}
                currentPage={this.state.pageCurrent}
                updateData={ this.updateData.bind(this) }
                setVisibilityOfResetSearchButton={this.setVisibilityOfResetSearchButton.bind(this)}
                handleSorter={this.handleSorter}
              />
            </React.Fragment>
          </React.Fragment>
        )
    );
  }
}

export default Returns;

/**
 * Component for our Manual-Returns feature
 * ("Multichannel Return") that is only
 * available to selected accounts. Allows editing
 * the Return details for a Manual-Return.
 * 
 * @component
 */

import React, { Component } from "react";
import axios from "axios";
import { Button, Form, Input, Row, Col, Select, DatePicker, Divider, message } from "antd";
import moment from "moment";
import { nonFrequentlyUsedCountries, frequentlyUsedCountries, USStateNames } from "../../../../constants/constants"

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ReturnDetailsManualReturnForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingCountryCode: undefined,
      vendorList: undefined,
      returnReasons: undefined,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/shop/manual/update");
            
      const errorStatus = response.data.error;
      if (errorStatus) {
        return message.error("Error loading your Multichannel-RMA settings.", 4)
      }

      const responseData = response.data;
      return this.setState({
        ...responseData,
        ...this.props.returnObject,
      });

    } catch(err) {
      return message.error("Error loading your Multichannel-RMA settings.", 4);
    }
  }

    handleCountryChange = (countryCode) => {
      this.setState({
        shippingCountryCode: countryCode,
      });
    };

    handleChange = () => {

    }

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields( async (err, values) => {
        if (err) {
          return message.error("There is something wrong with the data you entered. Please check again.", 5);
        }

        this.setState({
          loading: true,
        });
        try {
          const response = await axios.post("/api/shop/manual/update", {
            returnObject: this.props.returnObject,
            updatedReturn: values,
          });
          if (response.data.status === "error") {
            this.setState({
              loading: false,
            });
            return message.error("Error updating a manual Multichannel-RMA. Please try again.", 5);
          }
          this.setState({
            loading: false,
          });
          this.props.handleGetReturn()
          this.props.hideDrawer();
          return message.success("Manual Multichannel-RMA updated successfully.", 5);
        } catch (err) {
          this.setState({
            loading: false,
          });
          return message.error("Error updating a manual Multichannel-RMA. Please try again.", 5);
        }
      });
    }

    getCountriesList = () => {
      let countriesList = [];
      frequentlyUsedCountries.forEach((country) => {
        countriesList.push(<Option value={country.code}>{country.name}</Option>);
      });

      const disabledoption = <Option value="disabled" disabled={true}>---------</Option>;
      countriesList.push(disabledoption)
      nonFrequentlyUsedCountries.forEach((country) => {
        countriesList.push(<Option value={country.code}>{country.name}</Option>);
      });
        
      return countriesList;
    }

    getUSStateList = () => {
      const USStateList = USStateNames.map((state) => {
        return (
          <Option value={state.code}>{state.name}</Option>
        );
      });
        
      return USStateList;
    }

    render() {
      const { getFieldDecorator } = this.props.form;

      let arrayOfReturnReasons = [];
      let returnReasonsElements = [];
      if (this.state.returnReasons) {
        const returnReasons = this.state.returnReasons;
        arrayOfReturnReasons = returnReasons.split(/\r?\n/);
        // eslint-disable-next-line no-unused-vars
        returnReasonsElements = arrayOfReturnReasons.map((reason, index) => {
          return <Option value={ reason } key={ reason }>{ reason }</Option>
        });
      }

      let arrayOfVendors = [];
      let vendorElements = [];
      if (this.state.vendorList) {
        const vendorList = this.state.vendorList;
        arrayOfVendors = vendorList.split(/\r?\n/);
        vendorElements = arrayOfVendors.map((reason) => {
          return <Option value={ reason } key={ reason }>{ reason }</Option>
        });
      }

      return(
        <Form onSubmit={this.handleSubmit.bind(this)}>
                
          <Divider>Order information</Divider>
                
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Return status" {...formItemLayout}>
                {
                  getFieldDecorator("returnStatus", {
                    initialValue: this.state.returnStatus,
                    rules: [
                      { required: true, message: 'Please add a return status'  }
                    ]
                  })(
                    <Select onChange={ this.handleChange.bind(this) }>
                      <Option value="Pending">Pending</Option>
                      <Option value="Approved">Approved</Option>
                      <Option value="Shipped">Shipped</Option>
                      <Option value="In Transit">In Transit</Option>
                      <Option value="Received">Received</Option>
                      <Option value="Resolved">Resolved</Option>
                      <Option value="Rejected">Rejected</Option>
                    </Select>
                  )
                }
              </FormItem>
            </Col>
          </Row>
          {/* <Row type="flex" justify="start" align="top">
                    <Col span={24}>
                        <FormItem label="Resolution" {...formItemLayout}>
                            {
                                getFieldDecorator("resolution", {
                                    initialValue: this.state.resolution,
                                    rules: [
                                        { required: true, message: 'Please add a resolution'  }
                                    ]
                                })(
                                    <Select onChange={ this.handleChange.bind(this) }>
                                        <Option value="refundToExchange">Exchange</Option>
                                        <Option value="refundToOriginal">Refund</Option>
                                    </Select>
                                )
                            }
                        </FormItem>
                    </Col>
                </Row> */}
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Vendor" {...formItemLayout}>
                {
                  getFieldDecorator("vendor", {
                    initialValue: this.state.vendor,
                    rules: [
                      { required: true, message: 'Please choose a vendor'  }
                    ]
                  })(
                    <Select onChange={ this.handleChange.bind(this) }>
                      { vendorElements }
                    </Select>
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Order number" {...formItemLayout}>
                {
                  getFieldDecorator("orderNumber", {
                    initialValue: this.state.orderNumber,
                    rules: [
                      { required: true, message: 'Please add an order number'  }
                    ]
                  })(
                    <Input placeholder="Order number" />
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Order date" {...formItemLayout}>
                {
                  getFieldDecorator("orderDate", {
                    initialValue: this.state.orderDate ? moment(this.state.orderDate) : undefined,
                    rules: [
                      { required: true }
                    ]
                  })(
                    <DatePicker
                      onChange={this.handleChange.bind(this)}
                    />
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Date received" {...formItemLayout}>
                {
                  getFieldDecorator("dateReceived", {
                    initialValue: this.state.dateReceived ? moment(this.state.dateReceived) : undefined,
                  })(
                    <DatePicker 
                      onChange={this.handleChange.bind(this)}
                      placeholder="Select date (leave empty if not received)"
                    />
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Divider>Customer information</Divider>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem  label="Country" {...formItemLayout}>
                {getFieldDecorator('shippingCountryCode', {
                  initialValue: this.state.shippingCountryCode,
                  rules: [
                    { required: true, message: 'Please choose the country of the customer'  }
                  ]
                })(
                  <Select
                    placeholder="Select country"
                    onChange={ this.handleCountryChange.bind(this) }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    { this.getCountriesList() }
                  </Select>
                )}
              </FormItem>
              {
                this.state.shippingCountryCode !== "US"
                  ? null
                  : (
                    <FormItem label="State" {...formItemLayout}>
                      {getFieldDecorator('shippingProvinceCode', {
                        initialValue: this.state.shippingProvinceCode,
                        rules: [
                          { required: true, message: 'Please choose a state'  }
                        ]
                      })(
                        this.state.shippingCountryCode === "US"
                          ? (
                            <Select
                              placeholder="State / Province / Region"
                              onChange={ this.handleChange.bind(this) }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              { this.getUSStateList() }
                            </Select>
                          )
                          : (
                            <Select placeholder="State / Province / Region" onChange={ this.handleChange.bind(this) }>
                              <Option value="" key="disabled" disabled>State only needed for US based customers.</Option>
                            </Select>
                          )
                      )}
                    </FormItem>
                  )
              }
              <FormItem>
                {
                  getFieldDecorator("shippingFirstName", {
                    initialValue: this.state.shippingFirstName,
                    rules: [
                      { required: true, message: "Please add the customer's first name" }
                    ]
                  })(
                    <Input addonBefore="First name" />
                  )
                }
              </FormItem>
              <FormItem>
                {
                  getFieldDecorator("shippingLastName", {
                    initialValue: this.state.shippingLastName,
                    rules: [
                      { required: true, message: "Please add the customer's last name"  }
                    ]
                  })(
                    <Input addonBefore="Last name" />
                  )
                }
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress1', {
                  initialValue: this.state.shippingAddress1,
                  rules: [
                    { required: true, message: "Please add the customer's street address"  }
                  ]
                })(
                  <Input addonBefore="Street address" placeholder="Street and number, P.O. box, c/o." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress2', {
                  initialValue: this.state.shippingAddress2,
                })(
                  <Input addonBefore="(optional)" placeholder="Apartment, suite, unit, building, floor, etc." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingCity', {
                  initialValue: this.state.shippingCity,
                  rules: [
                    { required: true, message: "Please add the customer's city"  }
                  ]
                })(
                  <Input addonBefore="City" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingZip', {
                  initialValue: this.state.shippingZip,
                  rules: [
                    { required: true, message: "Please add the customer's zip code"  }
                  ]
                })(
                  <Input addonBefore="Zip Code" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('customerEmail', {
                  initialValue: this.state.customerEmail,
                  rules: [
                    { required: true, message: "Please add the customer's email"  }
                  ]
                })(
                  <Input addonBefore="E-Mail" placeholder="For delivering instructions via email" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingPhone', {
                  initialValue: this.state.shippingPhone,
                })(
                  <Input addonBefore="Phone number" placeholder="(optional) May be used to assist delivery" />
                )}
              </FormItem>
            </Col>
          </Row>

          <Divider>Notes</Divider>

          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Notes" {...formItemLayout}>
                {
                  getFieldDecorator("staffNotes", {
                    initialValue: this.state.staffNotes
                  })(
                    <TextArea rows={4} placeholder="Internal notes. Not visible to the customer." />
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit" loading={this.state.loading} disabled={this.state.loading}>
                  {
                    this.state.loading
                      ? <span>Saving return</span>
                      : <span>Save</span>
                  }
                </Button>
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
            <Col span={24}>
              <span>This will only update the details of the return and not trigger any additional actions.</span>
            </Col>
          </Row>
        </Form>
      )
    }
}
const WrappedReturnDetailsManualReturnForm = Form.create()(ReturnDetailsManualReturnForm);
export default WrappedReturnDetailsManualReturnForm;
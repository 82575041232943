/**
 * Component to Add product in our inventory reserve table
 *
 * @component
 */

import React, { Component } from 'react'
import {
  Modal,
  Divider,
  message,
  Col,
  Row,
  InputNumber,
  Select,
  Button,
  Input,
  List,
  Badge,
} from 'antd'
import StoreWideProducts from '../../../../portal/storeWideExchanges/StoreWideProducts'
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext'
import axios from 'axios'

const { Option } = Select;

class InventoryStock extends Component {
  static contextType = SubscriptionContext
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      returnSettings: {},
      additionChoice: undefined,
      productTagName: [],
      productTagList: props?.productTagList || [],
      reserveStockFields: props?.reserveStockFields || [],
      isLoading: false,
      position: props?.position,
      customizeField: 
        props?.position !== null && props?.position !== undefined ? 
          props?.reserveStockFields[props.position] : null,
      productQuantity: props?.position !== undefined ?
        props?.reserveStockFields[props.position].quantity : 1,
    }
  }

  onSave = async () => {
    this.setState({
      isLoading:true
    })
    const emptyFields = 
      (!this.state.additionChoice && !this.state.customizeField) || 
      (this.state.productTagName.length === 0 && !this.state.customizeField) || 
      (this.state.customizeField && this.state.customizeField?.title?.length === 0);
    if (emptyFields) {
      this.setState({
        isLoading:false
      })
      return message.error(
        "Please fill out all the fields",5
      );
    }
    const productTagList = [];
    this.state.reserveStockFields.forEach( field => {
      if (field.type === 'Tag') field.title.forEach(tag => productTagList.push(tag));
    })
    const payload = {
      productTagList,
      reserveStockFields: this.state.reserveStockFields,
    }
    try {
      const response = await axios.post("/api/returnSettings/update", payload);
      if (response.data.status === "error") {
        this.setState({
          isLoading:false
        })
        return message.error(
          "Error updating your exchange settings. Please try again.",
          5
        );
      }
      this.setState({
        isLoading:false
      })
      this.props.updateState(payload)
      this.props.onCancel();
      return message.success("Successfully updated.", 5);
    } catch (err) {
      this.setState({
        isLoading:false
      })
      return message.error(
        "Error updating your exchange settings. Please try again.",
        5
      );
    }
  }

  handleTagList = (values)=> {
    const tags = this.state.productTagList;
    let fields = this.state.reserveStockFields;
    fields = fields.filter( field => (
      field.type !== 'Tag' || field.title.every(tag => tags.includes(tag))
    ));
    const field = {
      type: 'Tag',
      title: values,
      quantity: this.state.productQuantity,
    }
    fields.push(field);
    this.setState({
      reserveStockFields: fields,
      productTagName: values
    })
  }

  editTagList = (value) => {
    const customizeField = this.state.customizeField;
    const fields = this.state.reserveStockFields;
    const index = this.state.position;
    const productTagList = this.state.productTagList.filter(tag => !this.state.customizeField.title.includes(tag))
    fields[index] = customizeField;
    customizeField.title = value;
    this.setState({
      customizeField,
      reserveStockFields:fields,
      productTagList
    })
  } 

  handleProductList = async (products, variant) => {
    const fields = this.state.reserveStockFields;
    const isExist = fields.find(field => field.variantId === variant.id);
    if (isExist) {
      const remainingFields = fields.filter(field => field.variantId !== variant.id);
      this.setState({
        reserveStockFields:remainingFields,
      })
      return;
    }
    const selectedProduct = products.find((product) => product.id === variant.product_id);
    const field = {
      type: 'Product',
      quantity: this.state.productQuantity,
    }
    if (selectedProduct?.variants?.length > 1) {
      field.variantTitle =  variant.title;
    }
    field.title =  selectedProduct.title;
    field.variantId = variant.id;
    fields.push(field);
    const productTagName = this.state.productTagName;
    productTagName.push(field);
    this.setState({
      reserveStockFields:fields,
      productTagName,
    })
    await this.onSave();
  }

  handleProductQuantity = (value) => {
    let fields = this.state.reserveStockFields;
    if (this.state?.position !== undefined) {
      const index = this.state.position;
      fields[index].quantity = value;
    } else {
      if (this.state.additionChoice === 'productTag') {
        const tags = this.state.productTagList;
        fields = fields.map(field => {
          if (field.type === 'Tag' && !field.title.every(tag => tags.includes(tag))) {
            return { ...field, quantity: value };
          }
          return field;
        });
      }
    }
    this.setState({
      productQuantity: value,
      reserveStockFields: fields,
    })
  }

  componentDidMount = async () => {
    try {
      const response = await axios.get('/api/returnSettings')
      if (response.data == null) {
        return
      }
      this.setState({
        returnSettings: response.data,
      })
    } catch (err) {
      message.error(`Error getting Return Settings from the server`)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          width={720}
          title="Customize customer note"
          visible={true}
          // onOk={this.props.onOk}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={12}>
              <span>Enter the product quantity to reserve the inventory.</span>
            </Col>
            <Col span={10}>
              <InputNumber
                min={1}  
                value={this.state.productQuantity}
                onChange={value => (this.handleProductQuantity(value))}                 
              />
            </Col>
          </Row>

          <Divider />

          <Row type="flex" justify="space-around" align="middle" style={{paddingTop: '10px'}}>
            <Col span={12}>
              <span>Select to add product tags or manually add a single product.</span>
            </Col>
            <Col span={10}>
              {this.props?.position !== undefined ?
                <Input
                  readOnly={true}
                  value={this.state.customizeField.type === 'Tag' ? 'Product tags' : 'Product'}
                />
                :
                <Select 
                  style={{width: '100%'}}
                  placeholder="Select your method"
                  onChange={(value)=>(this.setState({additionChoice: value}))}
                >
                  <Option value={"productTag"} key={"productTag"} >
                    Product tags
                  </Option>
                  <Option value={"singleProduct"} key={"singleProduct"} >
                    Product
                  </Option>
                </Select>
              }
            </Col>
          </Row>

          {this.state.additionChoice === 'productTag' &&
            <React.Fragment>
              <Divider />
              <Row type="flex" justify="space-around" align="top">
                <Col span={12}>
                  <span>Select the tags for which you want to reserve inventory.</span>
                </Col>
                <Col span={10}>
                  <Select
                    mode="multiple"
                    style={{width: '100%'}}
                    placeholder="Select product tag name"
                    showSearch={true}
                    onChange={(value)=>{this.handleTagList(value)}}
                  >
                    {this.props.productTagNames?.length > 0 ?
                      this.props.productTagNames.map(tag=> {
                        if (!this.state.productTagList.includes(tag)) {
                          return (
                            <Option value={tag} key={tag} >
                              {tag}
                            </Option>
                          )
                        }
                      })
                      : ""
                    }
                  </Select>
                </Col>
              </Row>
            </React.Fragment>
          }

          {this.props?.position !== undefined &&
            <React.Fragment>
              <Divider />
              <Row type="flex" justify="space-around" align="middle">
                <Col span={12}>
                  <span>{this.state.customizeField?.type === 'Tag' ? 'Select the tags for which you want to reserve inventory.' : 'Selected product'}</span>
                </Col>
                <Col span={10}>
                  {this.state.customizeField?.type === 'Tag' ?
                    <Select
                      mode="multiple"
                      style={{width: '100%'}}
                      placeholder="Select product tag name"
                      showSearch={true}
                      value={this.state.customizeField.title}
                      onChange={(value)=>{this.editTagList(value)}}
                    >
                      {this.props.productTagNames?.length > 0 ?
                        this.props.productTagNames.map(tag=> {
                          if (!this.state.productTagList.includes(tag) || this.state.customizeField.title.includes(tag)) {
                            return (
                              <Option value={tag} key={tag} >
                                {tag}
                              </Option>
                            )
                          }
                        })
                        : ""
                      }
                    </Select>
                    :
                    <List
                      style={{ "borderRadius": 4, "background": "#fbfbfd" }}
                      bordered={true}
                      itemLayout="horizontal"
                      dataSource={[this.state.customizeField]}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            className="exchangeItem"
                            title={
                              <Badge
                                count={"Selected Item"}
                                style={{ backgroundColor: '#52c41a'}}
                              />
                            }
                            description={<div style={{textAlign: 'center'}}>{item.title} {`${item.variantTitle ? ' - ' + item.variantTitle : ''}`}</div>}
                          />
                        </List.Item>
                      )}
                    />
                  }
                </Col>
              </Row>
            </React.Fragment>
          }

          {this.state.additionChoice === 'singleProduct' &&
            <React.Fragment>
              <Divider />
              <StoreWideProducts
                reserveInventory={true}
                handleProductList={this.handleProductList}
                companyIdentifier={this.context.identifier}
                returnSettings={this.state.returnSettings}
                isMerchantDashboard={true}
                reserveStockFields={this.state.reserveStockFields}
              />
            </React.Fragment>
          }

          <Divider />  

          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="dashboard-content"
          >
            <Col>
              <Button type="primary" onClick={this.onSave} loading={this.state.isLoading}>
                Save
              </Button>
            </Col>
          </Row>

          <Divider />
          
        </Modal>
      </React.Fragment>
    )
  }
}

export default InventoryStock

/**
 * Component to load the Table containing all of the Returns
 * within the main Returns-Dashboard.
 * 
 * @component
 */

import React from "react";
import { Table, Badge, Tooltip, Icon, Tag, Button } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import ReturnDetails from "./ReturnDetails";
import "../../../../styles/App.css"
import { trackFSEvent } from "../../../../helpers/fullstory";
const statusColor = {
  Pending: {
    color: '#b26600',
    backgroundColor: '#ffdc38',
    borderColor: '#f5c372'
  },
  Approved: {
    color: '#004085',
    backgroundColor: '#cce5ff',
    borderColor: '#b8daff'
  },
  Shipped: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba'
  },
  'In Transit': {
    color: '#383d41',
    backgroundColor: '#e2e3e5',
    borderColor: '#d6d8db'
  },
  Received: {
    color: '#3c368e',
    backgroundColor: '#e5deff',
    borderColor: '#c1c2ff'
  },
  Resolved: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#d4edda'
  },
  Rejected: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb'
  }
};

class ReturnsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      returnId: null,
      currentPage: 1,
    };
    this.resolutionOptions = {
      refundToOriginal: "Refund",
      refundToExchange: "Exchange",
      refundToCredit: "Store credit",
      advancedExchange: "Advanced Exchange",
      multiple: "Multiple",
      refundToCustom1: "Custom 1",
      refundToCustom2: "Custom 2",
      refundToCustom3: "Custom 3",
      storeWideExchange: "Store-Wide Exchange",
    };
    this.columns = [{
      title: '',
      dataIndex: 'errors',
      key: 'errors',
      render: (text, record) => (
        <React.Fragment>
          {
            record.labelStatus === "ERROR"
              ? (
                <React.Fragment>
                  <Badge status="error" />
                  <Tooltip placement="bottom" title={record.labelErrorTransactionSource ? record.labelErrorTransactionSource + ": " + record.labelErrorTransactionText + " (label could not be created & sent to customer)" : record.labelErrorTransactionText + " (label could not be created & sent to customer)"}>
                    <Icon type="info-circle" />
                  </Tooltip>
                </React.Fragment>
              )
              : <Badge status="success" />
          }
        </React.Fragment>
      ),
    }, {
      title: <span> Status <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'returnStatus',
      key: 'returnStatus',
      render: (text) => (
        <span>
          <Tag style={statusColor[text]} key={text}>{text}</Tag>
        </span>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('returnStatus')
        };
      },
      className: "filter"
    }, {
      title: <span> Return <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'returnNumber',
      key: 'returnNumber',
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('returnNumber')
        };
      },
      className: "filter"
    }, {
      title: <span> Order <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'orderName',
      key: 'orderName',
      render: (text, record) => {
        if (record.orderName) {
          return (
            <React.Fragment>
              <span>{record.orderName}</span>
            </React.Fragment>
          )
        } else {
          return (
            <span>{record.orderNumber}</span>
          )
        }

      },
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('orderNumber')
        };
      },
      className: "filter"
    }, {
      title: <span> Customer <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'customerFirstName',
      key: 'customerFirstName',
      render: (text, record) => (
        <span>{record.customerFirstName + " " + record.customerLastName}</span>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('customerFirstName')
        };
      },
      className: "filter"
    }, {
      title: <span> Request date <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: (text, record) => (
        <span> {moment(record.requestDate).format("MMM DD, YYYY")} </span>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('createdAt')
        };
      },
      className: "filter"
    }, {
      title: <span> Resolution <Icon type="shrink" rotate={135} /> </span>,
      dataIndex: 'resolution',
      key: 'resolution',
      render: (text, record) => {
        const isCustomResolution = record.resolution && record.resolution.includes("refundToCustom");
        if (isCustomResolution) {
          const customResolutionLabel = record.resolutionLabel;
          return (
            <Tooltip placement="top" title={customResolutionLabel}>
              <span>{this.resolutionOptions[record.resolution]}</span>
            </Tooltip>
          );
        } else {
          return (
            record.resolution
              ? this.resolutionOptions[text]
              : "Multiple"
          )
        }
      },
      onHeaderCell: () => {
        return {
          onClick: () => this.props.handleSorter('resolution')
        };
      },
      className: "filter"
    }, {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <Button type="primary" size="small" onClick={() => {
            this.setState({ drawerVisible: true, returnId: record.id }), trackFSEvent("Click Manage Return Order", {
              feature: `Return Setting`,
            });
          }} >
            Manage
          </Button>

        )
      },
    }];
    this.emptyColumns = [{
      title: 'Order No.',
    }, {
      title: 'Return No.',
    }, {
      title: 'Status',
    }, {
      title: 'Customer',
    }, {
      title: 'Request date',
    }, {
      title: 'Resolution',
    }, {
      title: 'Show',
    }];
    this.data = [];
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.props.setSelectedRows(selectedRowKeys, selectedRows);
  };

  triggerPagination = (page) => {
    const paginationObject = {
      current: page,
      pageSize: 10,
    }
    this.props.paginatePages(paginationObject)
    this.setState({
      currentPage: paginationObject.current
    })
  }

  hideDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
    if (!this.props.searchValue) {
      this.props.resetSearchResults(this.state.currentPage)
    } else if (this.props.searchValue) {
      this.props.searchTerm(this.props.searchValue)
    }
  }


  render() {
    if (isEmpty(this.props.returns)) {
      return <Table columns={this.emptyColumns} dataSource={this.data} pagination={false}/>
    }

    const rowSelection = {
      onChange: this.onSelectChange,
    };
    return (
      <React.Fragment>
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          rowKey='returnNumber'
          dataSource={this.props.returns}
          pagination={{
            total: this.props.returnsCount,
            current: this.props.currentPage,
            pageSize: 10,
            onChange: (page) => this.triggerPagination(page)
          }}
          scroll={{ x: true }}
        />
        {this.state.drawerVisible ?
          <ReturnDetails
            returnId={this.state.returnId}
            drawerVisible={this.state.drawerVisible}
            resolutionOptions={this.resolutionOptions}
            hideDrawer={this.hideDrawer}
            updateData={this.props.updateData.bind(this)}
            setVisibilityOfResetSearchButton={this.props.setVisibilityOfResetSearchButton.bind(this)}
          /> : ""
        }
      </React.Fragment>
    );
  }
}

export default ReturnsTable;

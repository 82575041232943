import React from 'react';
import { Layout, Icon, Select } from 'antd';
import { Helmet } from 'react-helmet';
import ReturnPortalRouter from '../../routes/ReturnPortal';
import ExternalPortalLanguage from '../../components/ExternalPortalLanguage';
import axios from 'axios';
import { TranslateContext } from '../../contexts/TranslationContext';
//styles
import '../../styles/Visitor.css';

// i18n
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../helpers/localePortal';

const { Header, Content } = Layout;
class ReturnPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: {},
      productData: {},
      returnSettings: {},
      brandName: undefined,
      storeUrl: undefined,
      storeShopifyUrl: undefined,
      faviconUrl: undefined,
      logoUrl: undefined,
      backgroundImageUrl: undefined,
      brandColor: undefined,
      brandColorButton: '#FFFFFF',
      brandColorButtonText: undefined,
      brandColorText: undefined,
      contactEmail: undefined,
      companyIdentifier: undefined,
      orderEmail: undefined,
      orderNumber: undefined,
      orderId: undefined,
      removeBackToStoreHeader: true,
      loadingSettings: true,
      brandFontStyle: undefined,
      brandFontSize: undefined,
      brandIncentivesColor: undefined,
      language: null,
      enableTranslation: false,
      enabledTranslations: []
    };
  }

  async componentDidMount() {
    try {
      const url = window.location.href;
      const urlObject = new URL(url);
      const companyIdentifier = urlObject.searchParams.get('identifier') || urlObject.searchParams.get('c');
      const returnNumber = urlObject.searchParams.get("returnNumber")
      if (companyIdentifier) {
        const response = await axios.post('/api/visitors/settings/brand', {
          companyIdentifier,
        });
        this.setState({
          ...response.data,
        });
        const responseWithResolutionOptions = await axios.post("/api/visitors/return/resolutions", {
          companyIdentifier,
          returnNumber,
        });
        this.setState({
          ...responseWithResolutionOptions.data
        });

        const listEnabledTranslations = response.data?.translations || [];
        const listTranslationCode = listEnabledTranslations.map((translation) => translation.languageCode) || [];
        const locale = getLocale(listTranslationCode);
        this.setState({
          language: locale,
          enabledTranslations: listEnabledTranslations,
        });
      }
    } catch (err) {
      console.log('Error while getting brand Settings', err);
    }
  }

  updateBrandSettings(settingsObject) {
    this.setState({
      ...settingsObject,
    });
  }

  handleCloseLoader = () => {
    this.setState({
      loadingSettings: false,
    });
  };

  handleLanguageChange = (language) => {
    this.setState({
      language,
    });
  };

  setEnableTranslation = (enable) => {
    this.setState({
      enableTranslation: enable,
    });
  };

  render() {
    const children = [];
    const enableTranslation = this.state.enableTranslation;
    const listTranslationCode = this.state.enabledTranslations?.map((translation) => translation.languageCode) || [];
    const defaultLanguage = getLocale(listTranslationCode);
    for (let i = 0; i < this.state.enabledTranslations?.length; i++) {
      children.push(
        <Select.Option key={this.state.enabledTranslations[i].languageCode}>
          {this.state.enabledTranslations[i].languageCode.toUpperCase()}
        </Select.Option>
      );
    }

    const languageDropdown = (
      <Select
        placeholder="Language"
        style={{ width: '5em' }}
        onChange={this.handleLanguageChange}
        defaultValue={defaultLanguage}
      >
        {children}
      </Select>
    );

    return (
      <TranslateContext.Provider
        value={{
          enableTranslation: this.state.enableTranslation,
          setEnableTranslation: this.setEnableTranslation,
        }}
      >
        <ExternalPortalLanguage
          brandName={this.state.brandName}
          handleCloseLoader={this.handleCloseLoader}
          language={this.state.language}
          transla
        >
          <Helmet>
            <link rel="icon" href={this.state.faviconUrl} />
          </Helmet>
          <Layout
            style={
              this.state.backgroundImageUrl
                ? {
                  backgroundImage: `url(${this.state.backgroundImageUrl})`,
                  backgroundSize: 'cover',
                  height: '100vh',
                  backgroundPosition: 'center',
                }
                : { background: this.state.brandColor, height: '100vh' }
            }
          >
            {this.state.removeBackToStoreHeader ? (
              enableTranslation && (
                <span style={{ marginLeft: 'auto', marginTop: 15, marginRight: 30 }}>
                  {languageDropdown}
                </span>
              )
            ) : (
              <Header
                style={{
                  background: '#fff',
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span style={{ float: 'left', marginLeft: 30 }}>
                  {this.state.loadingSettings ? null : (
                    <a
                      className="blackLink"
                      href={this.state.storeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon type="caret-left" theme="outlined" />
                      <FormattedMessage
                        id="app.visitor.returnToStore"
                        description="Link that redirects to the store"
                        defaultMessage="Return to {brandName} store"
                        values={{
                          brandName: this.state.brandName, 
                        }}
                      />
                    </a>
                  )}
                </span>
                {enableTranslation && Boolean(this.state.enabledTranslations.length) && (
                  <span style={{ marginRight: 30 }}>
                    {languageDropdown}
                  </span>
                )}
              </Header>
            )}
            <Content id="mainContentElement">
              <ReturnPortalRouter
                updateBrandSettings={this.updateBrandSettings.bind(this)}
                {...this.state}
              />
            </Content>
          </Layout>
        </ExternalPortalLanguage>
      </TranslateContext.Provider>
    );
  }
}

export default ReturnPortal;

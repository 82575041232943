import React, { Component } from 'react'
import axios from 'axios'
import {
  Input,
  Modal,
  Divider,
  Table,
  Button,
  Tooltip,
  Row,
  Spin,
  message,
  Icon,
} from 'antd'
import '../../../styles/Visitor.css'
const { Search } = Input
import { FormattedMessage } from "react-intl";

class StoreWideProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      searchValue: undefined,
      shopCurrency: null,
      modalProducts: [],
      storeWideOverselling: false,
      expandedRowId:""
    }
  }

  async componentDidMount() {
    try {
      const { companyIdentifier, returnNumber, currentProduct, returnSettings, isMerchantDashboard, currency } = this.props;
      const url = isMerchantDashboard ? '/api/returns/getStoreWideProducts' : '/api/visitors/return/getProducts'
      const productsResponse = await axios.post(url,{companyIdentifier,returnNumber})

      if (productsResponse.status !== 200 || !productsResponse.data?.products?.length) {
        throw new Error('Error while getting products from Shopify');
      }
      // Attach the image with variant object

      const products = productsResponse.data.products.map(product => {
        const variants = product.variants.map(variant => {
          const variantImage = product.images.find(image => image.variant_ids[0] === variant.id);
          if (variantImage) variant.image = variantImage;
          return variant;
        });
        product.variants = variants;
        return product;
      });

      const shopCurrency = currentProduct?.price_set?.shop_money?.currency_code || currency;
      this.setState({
        products,
        modalProducts: products,
        loading: false,
        shopCurrency,
        storeWideOverselling: returnSettings.storeWideOverselling,
      });
  
      const { exchangesEnableHighPriced } = returnSettings;
      if (returnSettings.storeWideOverselling && !exchangesEnableHighPriced) {
        const currentPrice = parseInt(currentProduct?.price_set?.shop_money?.amount, 10);
        const exchangeableProducts = products.filter(product =>
          product.variants.some(variant => parseInt(variant.price, 10) <= currentPrice)
        );
        this.setState({
          products: exchangeableProducts,
          modalProducts: exchangeableProducts,
        });
      }
      document.querySelector(".ant-modal-body").addEventListener('touchmove', (e) => {
        document.activeElement.blur();
      });
      return this.props.history?.push('/return/order?storeWideExchanges=true');
    } catch (error) {
      console.error('Error:', error);
      message.error('Error occurred during data retrieval', 4);
      this.setState({
        loading: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.location?.search) return this.props?.history.goBack();
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    const searchedProducts = this.state.products?.filter((product) => {
      const title = product?.title;
      return title && title.toUpperCase().includes(value.toUpperCase());
    });
  
    this.setState({
      modalProducts: searchedProducts,
      searchValue: value,
    });
  };

  handleResetSearch = () => {
    this.setState({
      searchValue: '',
      modalProducts: this.state.products,
    })
  }
  
  handleSelectedVariant = (record) => {
    const { products } = this.state;
    const selectedProduct = products.find((product) => product.id === record.product_id);
    if (selectedProduct) {
      selectedProduct.selectedVariant = record;
      this.props.handleSelectedProduct(selectedProduct)
      this.props.hideProducts(record)
    }
  };
  
  handleRowExpansion = (id) => {
    if (this.state.expandedRowId === id) {
      this.setState({ expandedRowId: undefined });
    } else {
      this.setState({ expandedRowId: id });
    }
  }

  handleBalanace = (price) => {
    if (this.props?.returnSettings?.storeWideBalance) return '';
    let currentPriceAfterDiscount = this.props.currentProduct?.price;
    let discount = this.props?.isMerchantDashboard ? 
      this.props.currentProduct?.discount
      :
      this.props.currentProduct?.discount_allocations && this.props.currentProduct?.discount_allocations[0]?.amount_set?.shop_money?.amount

    if (this.props?.returnSettings?.storeWideDiscount && discount) {
      currentPriceAfterDiscount = currentPriceAfterDiscount - discount;
    }
    
    return (currentPriceAfterDiscount - price) > 0 ? 
      <span className='positiveBalanceText'>{`You’re getting: ${(currentPriceAfterDiscount - price).toFixed(2)} ${this.state.shopCurrency}`}</span>
      :
      <span className='negativeBalanceText'>{`Left to pay: ${(price - currentPriceAfterDiscount).toFixed(2)} ${this.state.shopCurrency}`}</span>
  }

  handleLowStockRule = (tag, variant) => {
    let lowStock = false;
    const currentProductQuantity = this.props?.isMerchantDashboard ?
      this.props?.currentProduct.quantity
      :
      this.props.itemQuantity[`quantity_${this.props.currentProduct?.variant_id}`];
    const quantityCheck = variant.inventory_quantity < currentProductQuantity;
    if (this.props?.returnSettings?.inventoryStock) {

      const reserveStockFields = this.props?.returnSettings?.reserveStockFields;
      const variantExist = reserveStockFields?.find(field => field.variantId === variant.id);
      const tags = tag?.split(",");
      const tagExist = reserveStockFields?.find(field => field.type === "Tag" && field.title.some(tag => tags.includes(tag)));
      if (variantExist && tagExist) {
        const quantity = variantExist.quantity > tagExist.quantity ? variantExist.quantity :  tagExist.quantity;
        lowStock = variant.inventory_quantity < quantity;
      } else if (variantExist) {
        lowStock = variant.inventory_quantity < variantExist.quantity;
      } else if (tagExist) {
        lowStock = variant.inventory_quantity < tagExist.quantity;
      }

      return ( lowStock || quantityCheck );
    }
    return lowStock;
  }

  handleRowRander = (row) => {
    const nestedColumns = [
      {
        title: 'Title',
        key: 'title',
        render: (record) => <p>{record?.title}</p>,
        width: 240,
      },
      {
        title: 'Image',
        key: 'image',
        render: (product) => (
          <img
            src={product?.image?.src}
            style={{
              maxWidth: '100%',
              maxHeight: '100px',
              width: 'auto',
              height: 'auto',
            }}
            alt="Image"
          />
        ),
        width: 200,
      },
      {
        title: 'Action',
        key: 'action',
        width: 190,
        render: (_, record) => {
          if (!this.props?.reserveInventory) {
            const lowStock = this.handleLowStockRule(row.tags, record);
            return (record.inventory_quantity < 1 || lowStock) &&
            !this.state.storeWideOverselling ? (
                <div className='priceContainer'>
                  <Tooltip placement="bottom" title="Product is out of stock.">
                    <Button type="primary" disabled={true}> {record?.price} {this.state.shopCurrency} </Button>
                  </Tooltip>
                  {this.handleBalanace(record?.price)}
                </div>
              ) : (
                <div className='priceContainer'>
                  <Button type="primary" onClick={() => { this.handleSelectedVariant(record) }}>
                    {record?.price} {this.state.shopCurrency}
                  </Button>
                  {this.handleBalanace(record?.price)}
                </div>
              )
          } else {
            const products = this.state.products;
            const fields = this.props?.reserveStockFields;
            let isExist = false;
            if (fields?.length > 0) {
              isExist = fields.find(field => field.variantId === record.id)
            }
            return (
              <div className='priceContainer'>
                <Button 
                  type={isExist ? "secondary" : "primary"} 
                  onClick={() => { this.props.handleProductList(products, record) }}
                  disabled={isExist}
                >
                  {isExist ? 'Added' : 'Select'}
                </Button>
              </div>
            )
          }
        }
      },
    ]
    return (
      <Table
        rowKey={(record) => record.id}
        columns={nestedColumns}
        dataSource={row.variants}
        pagination={{ defaultPageSize: 5 }}
      />
    )
  }

  columns = [
    {
      title: 'Product',
      dataIndex: 'title',
      key: 'title',
      width: 240,
      render: (text, record) => (
        <span>
          {text}
          {record.variants.length === 1 && record.variants[0].title !=="Default Title" && ` (${record.variants[0].title})`}
        </span>
      ),
    },
    {
      title: 'Image',
      key: 'image',
      width:120,
      render: (product) => (
        <img
          src={product?.image?.src}
          style={{
            maxWidth: '100%',
            maxHeight: '100px',
            width: 'auto',
            height: 'auto',
          }}
          alt="Image"
        />
      ),
    },
    {
      title: <span style={{marginLeft:70}}>{this.props?.reserveInventory ? 'Action' : 'Price'}</span>,
      key: `${this.props?.reserveInventory ? 'Action' : 'Price'}`,
      width: 210,
      render: (product) => {
        const singleVariant = product.variants.length === 1
        if (!this.props?.reserveInventory){
          const productPrices = product?.variants.map((variant) => variant?.price);
          const minPrice = Math.min(...productPrices).toFixed(2)
          const maxPrice = Math.max(...productPrices).toFixed(2)
          const outOfStock = singleVariant && product.variants[0].inventory_quantity < 1;
          const oversellingEnabled = this.state.storeWideOverselling;
          if (minPrice === maxPrice) {
            if (singleVariant) {
              const lowStock = this.handleLowStockRule(product.tags, product.variants[0]);
              if ((outOfStock || lowStock ) && !oversellingEnabled) {
                return <div className='priceContainer'>
                  <Tooltip placement="bottom" title="Product is out of stock.">
                    <Button type="primary"  disabled={true}> {minPrice} {this.state.shopCurrency} </Button>
                  </Tooltip>
                </div>
                
              } else {
                return <div className='priceContainer'>
                  <Button type="primary" onClick={() => {this.handleSelectedVariant(product.variants[0])}}>
                    {minPrice} {this.state.shopCurrency}
                  </Button>
                  {this.handleBalanace(minPrice)}
                </div>
              }
            } else {
              return <div className='priceContainer'>
                <Button type="primary" onClick={() => {this.handleRowExpansion(product.id)}}>
                  {minPrice} {this.state.shopCurrency}
                </Button>
              </div>
            }
          } else {
            return <div className='priceContainer'>
              <Button type="primary" onClick={() => {{this.handleRowExpansion(product.id)}}}>
                {minPrice} {this.state.shopCurrency} - {maxPrice} {this.state.shopCurrency}
              </Button>
            </div>
          }
        } else {
          const products = this.state.products;
          if (singleVariant) {
            const fields = this.props?.reserveStockFields;
            let isExist = false;
            if (fields?.length > 0) {
              isExist = fields.find(field => field.variantId === product.variants[0].id)
            }
            return <div className='priceContainer'>
              <Button 
                type={isExist ? "secondary" : "primary"} 
                onClick={() => {this.props.handleProductList(products, product.variants[0])}}
                disabled={isExist}
              >
                {isExist ? 'Added' : 'Select'}
              </Button>
            </div>
          } else {
            return <div className='priceContainer'>
              <Button type="primary" onClick={() => {this.handleRowExpansion(product.id)}}>
                Expand
              </Button>
            </div>
          }
        }
      },
    },
  ]

  render() {
    const { intl } = this.props;
    return (
      <React.Fragment>
        {this.props.isMerchantDashboard === true ? 
          <React.Fragment>
            {this.state.loading ? (
              <Row type="flex" justify="space-around" align="middle">
                <Spin spinning={this.state.loading}></Spin>
              </Row>
            ) : (
              <React.Fragment>
                <Row type="flex" justify="center" align="middle">
                  {this.state.searchValue ? 
                    <Icon className="cross-icon-portal" style={{ color: "red" }} type="close" onClick={this.handleResetSearch} /> 
                    :  
                    null 
                  }
                  <Search
                    placeholder="Search for a Product"
                    enterButton="Search"
                    value={this.state.searchValue}
                    onChange={this.handleSearchChange}
                    style={{
                      width: 400,
                    }}
                  />
                </Row>
                <Divider />
                <Table
                  scroll={{ x:550 }}
                  rowKey={(record) => record.id}
                  columns={this.columns}
                  expandedRowRender={this.handleRowRander}
                  dataSource={this.state.modalProducts}
                  pagination={{ defaultPageSize: 5 }}
                  expandedRowKeys={[this.state.expandedRowId]}
                  onExpand={(_,record)=>this.handleRowExpansion(record.id)}
                  rowClassName={(record) => {
                    if (record.variants.length === 1) return 'NotExpandible'
                  }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
          : 
          <React.Fragment>
            {this.props.visible && (
              <Modal
                title={<FormattedMessage
                  id="app.visitor.storeWide.title"
                  description="Title of Store Wide products modal"
                  defaultMessage="Products"
                />}
                visible={true}
                onCancel={this.props.hideProducts}
                width={730}
              >
                {this.state.loading ? (
                  <Row type="flex" justify="space-around" align="middle">
                    <Spin spinning={this.state.loading}></Spin>
                  </Row>
                ) : (
                  <React.Fragment>
                    <Row type="flex" justify="center" align="middle">
                      {this.state.searchValue ? 
                        <Icon className="cross-icon-portal" style={{ color: "red" }} type="close" onClick={this.handleResetSearch} /> 
                        :  
                        null 
                      }
                      <Search
                        placeholder={intl.formatMessage({
                          id:"app.visitor.storeWide.search",
                          description:"Search store wide products",
                          defaultMessage:"Search for a Product",
                        })}
                        enterButton={intl.formatMessage({
                          id:"app.visitor.storeWide.searchBtn",
                          description:"Search Button for Store wide products",
                          defaultMessage:"Search",
                        })}
                        value={this.state.searchValue}
                        onChange={this.handleSearchChange}
                        style={{
                          width: 400,
                        }}
                      />
                    </Row>
                    <Divider />
                    <Table
                      scroll={{ x:550 }}
                      rowKey={(record) => record.id}
                      columns={this.columns}
                      expandedRowRender={this.handleRowRander}
                      dataSource={this.state.modalProducts}
                      pagination={{ defaultPageSize: 5 }}
                      expandedRowKeys={[this.state.expandedRowId]}
                      onExpand={(_,record)=>this.handleRowExpansion(record.id)}
                      rowClassName={(record) => {
                        if (record.variants.length === 1) return 'NotExpandible'
                      }}
                    />
                  </React.Fragment>
                )}
              </Modal>
            )}
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default StoreWideProducts

/**
 * Component for main Returns settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Button, Divider, Form, Input, Checkbox, InputNumber, message, Select, Icon, Tooltip, Alert, Typography } from "antd";
import ReturnLabel from "./components/ReturnLabel";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import { frequentlyUsedCountries, nonFrequentlyUsedCountries } from "../../../../constants/constants"
import ReturnMultipleAddress from "./components/ReturnMultipleAddress";
import ReturnReasonDialog from "./components/ReturnReasonDialog";
import ReturnNoteCusomize from "./components/ReturnNoteCustomize";
import ReturnCusomizeQuery from "./components/ReturnCustomizeQuery";
import { createShopifyUrl } from "../../../../helpers/createShopifyUrl"
import { truncateText } from "../../../../helpers/string";
import { trackFSEvent } from "../../../../helpers/fullstory";
import PhotoUpload from "./components/ReturnPhotoUpload";
import ReturnTemplate from "./components/ReturnTemplateDialog";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

class Settings extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      returnReasons: undefined,
      shuffleReturnReasons: false,
      excludedProductTags: undefined,
      refundToCredit: true,
      refundToOriginal: true,
      refundToExchange: true,
      returnOptionAdvancedExchange: false,
      refundToCustom1: false,
      refundToCustom2: false,
      refundToCustom3: false,
      refundToCustom1Label: undefined,
      refundToCustom2Label: undefined,
      refundToCustom3Label: undefined,
      returnTimeframe: 30,
      allowCustomDeliveryDate: true,
      returnAddress: undefined,
      returnPolicy: undefined,
      returnInstructions: undefined,
      autoAccept: false,
      enableAutoRefund: false,
      refundSettingsNotificationsCheck: false,
      methodLabelCreation: false,
      methodLabelManual: false,
      methodCustomerChoice: false,
      methodInStore: false,
      receiverName: undefined,
      receiverCompany: undefined,
      receiverStreet1: undefined,
      receiverStreet2: undefined,
      receiverCity: undefined,
      receiverState: undefined,
      receiverZip: undefined,
      receiverCountry: undefined,
      receiverPhone: undefined,
      receiverEmail: undefined,
      restockingFeeEnabled: undefined,
      restockingFeeValue: undefined,
      labelFeeEnabled: undefined,
      labelFeeValue: undefined,
      allowOrderUnfulfilled: false,
      allowOrderPaymentPending: false,
      allowOrderPaymentAuthorized: false,
      allowMultipleReturns: false,
      customerPhotoUploadEnabled: undefined,
      customerPhotoUploadRequired: undefined,
      customerPhotoUploadDescription: undefined,
      vendorList: undefined,
      showModal: false,
      showConfigPhotoUploadModel: false,
      ModalText: 'Content of the modal',
      visible: false,
      selectedStoreAddress: null,
      updateProps: false,
      orderRefundNote: false,
      orderRefundNoteValue: undefined,
      restockingFeeType: "fixed",
      restockingFeeIncludeTax: false,
      refundStatus: "Approved",
      modalVisible: false,
      useGiftApiForStoreCredit: false,
      useDiscountCodeForStoreCredit: false,
      allScopesIncluded: undefined,
      shopifyPlanName: undefined,
      shopifyUrl: undefined,
      enableHtmlEmailTemplates: undefined,
      migrateHtmlContent: undefined,
      returnOptionStoreWideExchange: false,
      updateReturnResolution: false,
      modalCustomize: false,
      modalCustomQuery: false,
      indexModalCustomQuery: null,
      additionalFields: [],
      enteredExcludeProductTags: "",
      trackingChange: {},
      upsEnabled: undefined,
      enableConvenience: false,
    };
  }

  showAddressModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  getReturnSettings = async () => {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }
  async componentDidMount() {
    if (this.context.featureMultipleAddress) {
      this.setState({
        updateProps: true
      })
    }
    this.getReturnSettings();
    this.checkScopesAndPlan();
    this.generateShopifyUrl();
  }

  generateShopifyUrl = async () => {
    const shopifyUrl = await createShopifyUrl('/dashboard/settings');
    this.setState({
      shopifyUrl,
    });
  }

  checkScopesAndPlan = async () => {
    try {
      const response = await axios.get("/api/returnSettings/scopes");
      if (response.data == null) {
        return;
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error("Error retrieving access scopes and plan. Try again.", 5);
    }
  }

  showConfigureModal = (showModal = true) => {
    this.setState({
      showModal: showModal
    });
  }

  showConfigPhotoUploadModel = (showConfigPhotoUploadModel = true) => {
    this.setState({
      showConfigPhotoUploadModel: showConfigPhotoUploadModel
    });
  }

  showReturnModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  //Handle open return template
  showTemplateModal = () => {
    this.setState({
      modalTemplate: true,
    });
  }

  handleCancelModalTemplate = () => {
    this.setState({
      modalTemplate: false,
    });
  }

  //Handle open note customize
  showCustomizeNoteModal = () => {
    this.setState({
      modalCustomize: true,
    });
  };

  handleModalCustomize = () => {
    this.setState({
      modalCustomize: false,
    });
  };

  handleCancelModalCustomize = () => {
    this.setState({
      modalCustomize: false,
    });
  };

  //Handle open customize query
  showCustomizeQueryModal = (position) => {
    this.setState({
      modalCustomQuery: true,
      indexModalCustomQuery: position === null ? null : position,
    });
  };

  handleCustomizeQueryModal = () => {
    this.setState({
      modalCustomQuery: false,
    });
  };

  handleCancelCustomizeQueryModal = () => {
    this.setState({
      modalCustomQuery: false,
    });
  };

  updateState = (newState) => {
    this.setState({
      ...newState
    });
  }

  removeStringWhitespace = (strParam) => {
    if (strParam == null || strParam === "") {
      return null;
    }
    let strArray = strParam.split('\n');
    strArray = strArray.filter((str) => {
      str = str.trim();
      if (str != '') return true;
      return false;
    });
    return strArray.join('\n');
  }

  updateStoreAddress = async (id, values) => {
    try {
      const response = await axios.post("/api/returnSettings/multipleAddress/update", {
        id,
        values
      });

      if (response.data.status === "error") {
        return message.error("Error, Unable to add store address, Please try again.", 5);
      }

    } catch (err) {
      return message.error("Error, Unable to update store address, Please try again.", 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.enteredExcludeProductTags.length > 0) {
      trackFSEvent("Enter Excluded Product-tags", {
        feature: "Return Settings",
        tagName: this.state.enteredExcludeProductTags
      });
    }
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error("There is something wrong with the data you entered. Please check again.", 5);
      }
      this.trackingEvents(values);
      try {
        const excludedProductTags = this.removeStringWhitespace(values.excludedProductTags);
        const returnReasons = this.removeStringWhitespace(values.returnReasons);

        this.props.form.setFieldsValue({
          ["excludedProductTags"]: excludedProductTags,
          ["returnReasons"]: returnReasons,
        });

        if (this.context.featureMultipleAddress) {
          this.updateStoreAddress(this.state.selectedStoreAddress, values)
        }

        const response = await axios.post("/api/returnSettings/update", {
          ...values,
          excludedProductTags,
          returnReasons,
        });
        if (response.data.status === "error") {
          return message.error("Error updating your return settings. Please try again-----.", 5);
        }
        this.setState({
          ...response.data,
        });
        return message.success("Return settings successfully updated.", 5);
      } catch (err) {
        return message.error("Error updating your return settings. Please try again.", 5);
      }
    });
  }

  handleChange = (e) => {
    const target = e.target.id;
    const targetOptions = ['refundToCredit', 'refundToOriginal', 'refundToExchange', 'returnOptionAdvancedExchange', 'returnOptionStoreWideExchange'];
    const targetReturnMethod = ['methodLabelCreation', 'methodLabelManual', 'methodCustomerChoice', 'methodInStore'];
    const returnOptionChange = targetOptions.includes(target);
    const returnMethodChange = targetReturnMethod.includes(target);
    const targetEnableAutoRefund = target === 'enableAutoRefund';
    const targetEnableCustomerUploadPhoto = target === 'customerPhotoUploadEnabled';
    const restockingFeeEnabled = target === 'restockingFeeEnabled';
    const labelFeeEnabled = target === 'labelFeeEnabled';
    const allowForUnpaidAndUnfulfilledOrders = ['allowOrderUnfulfilled', 'allowOrderPaymentPending', 'allowOrderPaymentAuthorized', 'allowMultipleReturns'].includes(target);
    this.setState({
      [target]: e.target.checked,
      trackingChange: {
        ...this.state.trackingChange,
        enableAutoRefund: targetEnableAutoRefund,
        returnOption: returnOptionChange,
        customerPhotoUploadEnabled: targetEnableCustomerUploadPhoto,
        returnMethodChange,
        restockingFeeEnabled,
        labelFeeEnabled,
        allowForUnpaidAndUnfulfilledOrders,
      }
    });
  }

  handleRefundStatusType = (value) => {
    this.setState({
      refundStatus: value
    })
  }
  handleRestockingFeeType = (value) => {
    this.setState({
      restockingFeeType: value
    })
  }
  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code} key={`frequentlyUsedCountries${country.code}`}>{country.name}</Option>);
    });

    const disabledoption = <Option value="disabled" key={'disabled'} disabled={true}>---------</Option>;
    countriesList.push(disabledoption)
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code} key={`nonFrequentlyUsedCountries${country.code}`}>{country.name}</Option>);
    });
    return countriesList;
  }

  handleStateValidator = (rule, value, callback) => {
    try {
      const receiverCountry = this.props.form.getFieldValue('receiverCountry');
      if (!value && receiverCountry === "US") {
        callback('This field is required for United States');
      } else {
        callback()
      }
    }
    catch (err) {
      callback(err);
    }
  }

  handleChangeMultipleAddress = (id, storeAddresses) => {

    this.setState({
      selectedStoreAddress: id
    })

    const storeAddress = storeAddresses.filter(e => {
      return e.id === id
    })
    storeAddress.map((address) => {
      this.props.form.setFieldsValue({
        receiverName: address.fullName,
        receiverCompany: address.company,
        receiverStreet1: address.streetAddress,
        receiverStreet2: address.optional,
        receiverCountry: address.country,
        receiverCity: address.city,
        receiverZip: address.zipCode,
        receiverEmail: address.email,
        receiverPhone: address.phone,
      })
    });
  }

  migrateHtmlContent = async () => {
    try {
      const response = await axios.post("/api/returnSettings/migrateData");
      if (response.status != 200) {
        return message.error("Error, Unable to migrate html data, Please try again.", 5);
      }
      this.setState({
        migrateHtmlContent: true
      })
      return message.success("Data migrated successfully.", 5);

    } catch (err) {
      return message.error("Error, Unable to migrate html data, Please try again.", 5);
    }
  }

  onChangeEnteredExcludeProductTags = (e) => {
    this.setState({ enteredExcludeProductTags: e.target.value });
  }

  trackingEvents = (values) => {
    trackFSEvent("Change Return settings", {
      feature: "Return Settings",
      enableAutomaticallyAcceptReturns: this.state.autoAccept || false,
      enableAutoRefund: this.state.enableAutoRefund || false,
      enableSendANotificationToTheCustomer: this.state.refundSettingsNotificationsCheck || false,
      enableHTMLEmailTemplate: this.state.enableHtmlEmailTemplates || false,
      enableResolutionHub: this.state.updateReturnResolution || false,
      enableCustomerIsResponsibleForLabelFee: this.state.methodCustomerPaidLabel || false,
      enableRestockingFeeLabelFee: this.state.restockingFeeEnabled || false
    });
    if (values.returnTimeframe !== this.state.returnTimeframe) {
      trackFSEvent("Set Return Timeframe - in days", {
        feature: "Return Settings",
        timeframe: values.returnTimeframe
      });
    }
    if (this.state.trackingChange?.enableAutoRefund) {
      trackFSEvent("Enable auto refund", {
        feature: "Return Settings",
        enableAutoRefund: values.enableAutoRefund,
        refundStatus: values.refundStatus,
        notificationSent: values.refundSettingsNotificationsCheck
      });
    }
    if (this.state.trackingChange?.returnOption) {
      trackFSEvent("Custom Return options", {
        feature: "Return Settings",
        returnOptions: `${values.refundToCredit ? 'Refund to store credit' : ''}-${values.refundToOriginal ? 'Refund to original payment method' : ''}-${values.refundToExchange ? 'Exchange product' : ''}-${values.returnOptionAdvancedExchange ? 'Advanced Exchange' : ''}-${values.returnOptionStoreWideExchange ? 'Store-Wide Exchange' : ''}`,
      });
    }
    if (this.state.trackingChange.returnMethodChange) {
      trackFSEvent("Select Return methods", {
        feature: "Return Settings",
        returnOptions: `${values.methodLabelCreation ? 'Pre-Paid Return Label (automatic)' : ''}-${values.methodLabelManual ? 'Pre-Paid Return Label (manual) ' : ''}-${values.methodCustomerChoice ? 'Customer is responsible for Return Shipping' : ''}-${values.methodInStore ? 'In-Store Return' : ''}`,
      });
    }
    if (this.state.trackingChange.customerPhotoUploadEnabled) {
      trackFSEvent("Upload Customer Photo", {
        feature: "Return Settings",
        required: values.customerPhotoUploadRequired,
        uploadStatus: values.customerPhotoUploadEnabled
      });
    }
    if (this.state.trackingChange.restockingFeeEnabled && values.restockingFeeEnabled) {
      trackFSEvent("Enable Restocking-fee", {
        feature: "Return Settings",
        feeType: values.restockingFeeType,
        feeAmount: values.restockingFeeValue
      });
    }
    if (this.state.trackingChange.restockingFeeEnabled && !values.restockingFeeEnabled) {
      trackFSEvent("Disable Restocking-fee", {
        feature: "Return Settings",
        feeType: values.restockingFeeType,
        feeAmount: values.restockingFeeValue
      });
    }
    if (this.state.trackingChange.restockingFeeEnabled && values.labelFeeEnabled) {
      trackFSEvent("Enable Pre-paid Label Fee", {
        feature: "Return Settings",
        labelFeeAmount: values.labelFeeValue,
      });
    }
    if (this.state.trackingChange.restockingFeeEnabled && values.labelFeeEnabled) {
      trackFSEvent("Disable Pre-paid Label Fee", {
        feature: "Return Settings",
        labelFeeAmount: values.labelFeeValue,
      });
    }
    if (this.state.trackingChange.allowForUnpaidAndUnfulfilledOrders) {
      trackFSEvent("Allow for Unpaid and Unfulfilled orders", {
        feature: "Return Settings",
        allowReturnMethods: `${values.allowOrderUnfulfilled ? 'Allow Returns for Unfulfilled Orders' : ''}-${values.allowOrderPaymentPending ? 'Allow Returns for Pending Payments' : ''}-${values.allowOrderPaymentAuthorized ? 'Allow Returns for Authorized Payments' : ''}-${values.allowMultipleReturns ? 'Allow multiple Returns' : ''}`,
      });
    }
    if (this.state.receiverCountry !== values.receiverCountry || this.state.receiverName !== values.receiverName) {
      trackFSEvent("Add a Return address", {
        feature: "Return Settings",
        addressInformation: `${values.receiverCountry}, ${values.receiverName}`
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const additionalFields = this.state.additionalFields || []
    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top" style={{ paddingBottom: 35 }}>
          <Col>
            <h1 className="pageTitle">Return settings</h1>
            <p>Set your return policy and define resolution options as well as details of the shipping process.</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={22}>
            <Alert
              message="Important"
              description={<span>Set a rule for your business and staff to <strong>NOT delete products</strong> from your Shopify Store for orders that are still eligible for return. Deleting a product will remove the associated product data from all existing orders in your Shopify Store. Your customers will not be able to start a return for orders with invalid order-data. Shopify offers functionality to archive products instead of deleting them.</span>}
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit} className="removeFormMargin">
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            {
              <React.Fragment>
                <Col span={10}>
                  <h4>Return reasons</h4>
                  <span>Enter as many reasons as you like. Now you can also add translation for reasons.</span>
                </Col>
                <Col span={10}>
                  <Button type="primary" onClick={this.showReturnModal}>
                    Manage Return Reasons
                  </Button>
                  {this.state.modalVisible ?
                    <ReturnReasonDialog
                      visible={this.state.modalVisible}
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      featureMultilangReturnReasons={this.context.featureMultilangReturnReasons}
                    /> :
                    null
                  }
                </Col>
              </React.Fragment>
            }
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <React.Fragment>
            <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
              <Col span={10}>
                <h4 style={{marginBottom: 'unset'}}>Enable the Convenience Return Template</h4>
              </Col>
              <Col span={10} style={{display: 'flex', alignItems: 'center'}}>
                <FormItem>
                  {getFieldDecorator('enableConvenience', {})(
                    <Checkbox 
                      checked={this.state.enableConvenience} 
                      onChange={(e) => this.handleChange(e)}>
                      Enable
                    </Checkbox>
                  )}
                </FormItem>
                <Button style={{marginLeft: '10px'}} type="secondary" onClick={this.showTemplateModal} disabled={!this.state.enableConvenience}>
                  Configure
                </Button>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
              <Col span={10}>
                <span>Manage the convenience return template to make the return process quicker and more convenient.</span>
              </Col>
              <Col span={10}>
                {this.state.modalTemplate ?
                  <ReturnTemplate
                    visible={this.state.modalTemplate}
                    onOk={this.handleOk}
                    onCancel={this.handleCancelModalTemplate}
                  /> :
                  null
                }
              </Col>
            </Row>
          </React.Fragment>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Customize customer journey</h4>
              <span>Create custom queries to communicate with your customers. You can make the customer notes section optional or required. You can also add additional questions and create checkboxes or radio-buttons to get the information you need from your customers.</span>
            </Col>
            <Col span={10}>
              <Row span={10}>
                <Col span={24} style={{ display: "flex", justifyContent: "space-between"}}>
                  <Col span={12} style={{ paddingTop: "5px" }}>
                    <span>Customize Customer Notes.</span>
                  </Col>
                  {this.state.modalCustomize ?
                    <ReturnNoteCusomize
                      visible={this.state.modalCustomize}
                      onOk={this.handleModalCustomize}
                      onCancel={this.handleCancelModalCustomize}
                    /> :
                    null
                  }
                  <Button type="custom-button" onClick={this.showCustomizeNoteModal}>
                    Customize
                  </Button>
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <p>Press Add to add multiple questions to your customer's return journey</p>
              </Row>
              <Row style={{ padding: 2 }}>
                {additionalFields && additionalFields.length > 0 && 
                <React.Fragment>
                  {additionalFields.map((item, index) => {
                    return <Row key={index}>
                      {this.state.modalCustomQuery && this.state.indexModalCustomQuery === index ?
                        <ReturnCusomizeQuery
                          position={index}
                          visible={this.state.modalCustomQuery}
                          onOk={this.handleCustomizeQueryModal}
                          onCancel={this.handleCancelCustomizeQueryModal}
                          additionalFields={additionalFields}
                        /> :
                        null
                      }
                      <Col span={16}>
                        Q{index + 1}: Type: {item.type}. Title: {truncateText(item.title, 20)}
                      </Col>
                      <Col span={8} style={{ display: "flex", justifyContent: "end"}}>
                        <Button className="custom-button" onClick={() => {this.showCustomizeQueryModal(index)}} stype={{ height: 10 }}>
                          Edit
                        </Button>
                      </Col>
                    </Row>
                  })}
                </React.Fragment>}
              </Row>
              <Row span={10}>
                <Col span={24}>
                  {this.state.modalCustomQuery && (this.state.indexModalCustomQuery === null || this.state.indexModalCustomQuery === undefined) ?
                    <ReturnCusomizeQuery
                      visible={this.state.modalCustomQuery}
                      onOk={this.handleCustomizeQueryModal}
                      onCancel={this.handleCancelCustomizeQueryModal}
                      additionalFields={additionalFields}
                    /> :
                    null
                  }
                  <Row span={24} style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" onClick={() => {this.showCustomizeQueryModal(null)}} disabled={additionalFields.length >= 5}>
                      <span style={{ width: "75px" }}>Add</span>
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row span={24} style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                {additionalFields.length}/5 questions already added
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Shuffle return reasons</h4>
              <span>Randomize the order of return reasons for each item on the returns-portal to guard against possible selection bias from the customer.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('shuffleReturnReasons', {})(
                  <Checkbox checked={this.state.shuffleReturnReasons} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Excluded product-tags (optional)</h4>
              <span>Exclude certain products from being eligible for return based on one or more product-tags that you set on your Shopify product-page. One product-tag per line (no comma inbetween).</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('excludedProductTags', {
                  initialValue: this.state.excludedProductTags,
                })(
                  <TextArea rows={6} placeholder="Enter 1 product-tag per line (no comma inbetween)" onChange={this.onChangeEnteredExcludeProductTags} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Return timeframe - in days</h4>
              <p>How long should customers be able to return products after the order has been delivered?</p>
              <p><strong>NOTE:</strong> The fulfillment status for orders in your Shopify store has to be set correctly by your Store for this to work. If we cannot find a "delivered" event for an order we fall back to the last date the fulfillment has been updated. Therefore add a few days as a buffer if your store does not update the fulfillment status of its orders as intended by Shopify.</p>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('returnTimeframe', {
                  initialValue: this.state.returnTimeframe,
                  rules: [{ required: true, message: 'Please enter the timeframe for customers to make returns.' }],
                })(
                  <InputNumber min={0} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Automatically accept returns</h4>
              <span>Check this box if you want to automatically accept a return initiated by a customer. If you enabled sending an email to the customer under "Email settings" this will trigger the "Approved" Email if activated (and skip the "Pending" email).</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('autoAccept', {
                })(
                  <Checkbox checked={this.state.autoAccept} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          {
            (this.context.featureAutoRefund) &&
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row><Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Enable auto refund</h4>
                  <span>Check this box if you want to enable automatic refunds. If this Checkbox is enabled, while approving an order, the refund will be generated automatically.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('enableAutoRefund', {})(
                      <Checkbox checked={this.state.enableAutoRefund} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </React.Fragment>
          }
          {
            (this.context.featureAutoRefund && this.state.enableAutoRefund) &&
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <span>By default on Approved status refund will be generated automatically but you can change the status preference on which you want to generate refund.</span>
                </Col>
                <Col span={10}>
                  <FormItem label="Select Status" >
                    {getFieldDecorator('refundStatus', {
                      initialValue: this.state.refundStatus
                    })(
                      <Select
                        disabled={!this.state.enableAutoRefund}
                        onChange={this.handleRefundStatusType}
                      >
                        <Option value="Approved">Approved</Option>
                        <Option value="Shipped">Shipped</Option>
                        <Option value="In Transit">In Transit</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Resolved">Resolved</Option>
                        <Option value="Rejected">Rejected</Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <span>Send a notification to the customer</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('refundSettingsNotificationsCheck', {})(
                      <Checkbox checked={this.state.refundSettingsNotificationsCheck} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Attach a note to the Order</h4>
                  <span>This will not be visible to the customer. Only you and other staff can see this reason.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('orderRefundNote', {})(
                      <Checkbox checked={this.state.orderRefundNote} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator("orderRefundNoteValue", {
                      initialValue: this.state.orderRefundNoteValue,
                    })(
                      <Input
                        placeholder="Refund Shopify Note"
                        disabled={!this.state.orderRefundNote}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </React.Fragment>
          }
          {
            (this.context.featureStoreCredit) === true &&

            (!this.state.allScopesIncluded ?
              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>To enable Store-Credit via gift card or discount code please click to proceed </h4>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      <Button type="primary"
                        href={this.state.shopifyUrl}
                      >
                        Enable Scopes
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>
              :
              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                {
                  (this.state.shopifyPlanName === "enterprise" || this.state.shopifyPlanName === "shopify_plus") &&
                  <React.Fragment>
                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                      <Col span={10}>
                        <h4>Enable feature gift card</h4>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('useGiftApiForStoreCredit', {})(
                            <Checkbox checked={this.state.useGiftApiForStoreCredit} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>Enable feature discount code</h4>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('useDiscountCodeForStoreCredit', {})(
                        <Checkbox checked={this.state.useDiscountCodeForStoreCredit} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>
            )
          }
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          {
            <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
              {!this.state.migrateHtmlContent ?
                <React.Fragment>
                  <Col span={10}>
                    <h4>Enable HTML email template</h4>
                    <span>Click to migrate HTML content</span>
                  </Col>
                  <Col span={10}>
                    <Button type="primary" onClick={this.migrateHtmlContent} style={{ marginRight: '2%' }}>Migrate HTML Content</Button>
                    <Tooltip
                      placement="bottom"
                      title="This is a one time thing, it will migrate all email content to HTML in new columns. By migrating the email content in this way, we ensure that the text and formatting of your emails remain intact without any issues.
                      However, please note that you have the flexibility to modify the email format at any time through the email settings. Once you click on 'Migrate HTML Content,' a checkbox will appear, allowing you to effortlessly switch between the current simple text email format and the new HTML email format."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </Col>
                </React.Fragment>
                :
                <React.Fragment>
                  <Col span={10}>
                    <h4>Enable HTML email template</h4>
                    <span>Check this box if you want to enable html email template. </span>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('enableHtmlEmailTemplates', {})(
                        <Checkbox checked={this.state.enableHtmlEmailTemplates} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </React.Fragment>
              }
            </Row>
          }
          {(this.context.featureUpdateResoultion) &&
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <React.Fragment>
                  <Col span={10}>
                    <h4>Enable ResolutionHub</h4>
                    <span>Check this box if you want to be able to update the return resolution of a
                      product in a return. This can be done in the <b>Manage</b> section.<br /><br />
                    <strong>IMPORTANT: </strong>Only applicable for pending returns.
                    </span>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('updateReturnResolution', {})(
                        <Checkbox checked={this.state.updateReturnResolution} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </React.Fragment>
              </Row>
            </React.Fragment>
          }
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Return options</h4>
              <span>Select possible resolution options that suit your business case.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('refundToCredit', {
                })(
                  <Checkbox checked={this.state.refundToCredit} onChange={(e) => this.handleChange(e)}>Refund to store credit</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('refundToOriginal', {
                })(
                  <Checkbox checked={this.state.refundToOriginal} onChange={(e) => this.handleChange(e)}>Refund to original payment method</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('refundToExchange', {
                })(
                  <Checkbox checked={this.state.refundToExchange} onChange={(e) => this.handleChange(e)}>Exchange product</Checkbox>
                )}
                {
                  this.context.featureExchangeSelection
                    ? (
                      <Button href="/dashboard/settings/exchanges/variants">Configure Variant-Exchange</Button>
                    )
                    : null
                }
              </FormItem>
              {this.context.featureAdvancedExchanges
                ? (
                  <FormItem>
                    {getFieldDecorator('returnOptionAdvancedExchange', {
                    })(
                      <Checkbox checked={this.state.returnOptionAdvancedExchange} onChange={(e) => this.handleChange(e)}>Advanced Exchange</Checkbox>
                    )}
                    <Button href="/dashboard/settings/exchanges/advanced">Configure Advanced-Exchange</Button>
                  </FormItem>
                )
                : null
              }
              {this.context.featureStoreWideExchange
                ? (
                  <FormItem>
                    {getFieldDecorator('returnOptionStoreWideExchange', {
                    })(
                      <Checkbox checked={this.state.returnOptionStoreWideExchange} onChange={(e) => this.handleChange(e)}>Store-Wide Exchange</Checkbox>
                    )}
                    <Button href="/dashboard/settings/exchanges/storeWideExchange">Configure Store-Wide-Exchange</Button>
                  </FormItem>
                )
                : null
              }
              <Col span={24}>
                <Col span={6}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom1', {
                    })(
                      <Checkbox checked={this.state.refundToCustom1} onChange={(e) => this.handleChange(e)}>Custom Option</Checkbox>
                    )}
                  </FormItem>
                </Col>
                <Col span={18}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom1Label', {
                      initialValue: this.state.refundToCustom1Label,
                    })(
                      <Input addonBefore="Text for customer" placeholder="Add text that is shown to the customer" />
                    )}
                  </FormItem>
                </Col>
              </Col>
              <Col span={24}>
                <Col span={6}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom2', {
                    })(
                      <Checkbox checked={this.state.refundToCustom2} onChange={(e) => this.handleChange(e)}>Custom Option</Checkbox>
                    )}
                  </FormItem>
                </Col>
                <Col span={18}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom2Label', {
                      initialValue: this.state.refundToCustom2Label,
                    })(
                      <Input addonBefore="Text for customer" placeholder="Add text that is shown to the customer" />
                    )}
                  </FormItem>
                </Col>
              </Col>
              <Col span={24}>
                <Col span={6}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom3', {
                    })(
                      <Checkbox checked={this.state.refundToCustom3} onChange={(e) => this.handleChange(e)}>Custom Option</Checkbox>
                    )}
                  </FormItem>
                </Col>
                <Col span={18}>
                  <FormItem>
                    {getFieldDecorator('refundToCustom3Label', {
                      initialValue: this.state.refundToCustom3Label,
                    })(
                      <Input addonBefore="Text for customer" placeholder="Add text that is shown to the customer" />
                    )}
                  </FormItem>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={22}>
              <Alert
                message="How to set up automated return labels?"
                description={
                  <span>We cover the setup of automated return labels <a href="https://support.apphub.com/en/articles/6338243-how-to-set-up-automated-return-labels" target="_blank" rel="noopener noreferrer">
                    here in our Help Docs
                  </a>
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <br />
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Return methods</h4>
              <p>Select at least one return method.</p>
              <p>(Return labels are optional) After you set up automated return labels with a supported carrier they will be sent out via email to the customer once the return has been approved. You can find the corresponding email templates here: <a href="/dashboard/email">email template</a>.</p>
              <p>Hover over the 'i' icon to understand the difference between automatic and manual prepaid-labels. If you make use of prepaid-labels make sure to only enable one of both options.</p>
            </Col>

            <Col span={10}>
              <FormItem>
                {getFieldDecorator('methodLabelCreation', {
                })(
                  <Checkbox checked={this.state.methodLabelCreation} onChange={(e) => this.handleChange(e)}>Pre-Paid Return Label (automatic) <Tooltip title="This option uses our integration with Shippo to automatically generate the label. The label is shown directly to the customer in the return-portal (if you have the auto-approve option enabled) and you can automatically send the label and tracking-link to the customer in the 'approved' email template (make sure to include the dynamic variables for label and tracking)."><Icon type="info-circle" /></Tooltip></Checkbox>
                )}
                <Button onClick={this.showConfigureModal}>Configure</Button>
                {this.state.showModal && 
                  <ReturnLabel 
                    key="returnLabel"
                    showConfigureModal={this.showConfigureModal} 
                    state={this.state} 
                    setParentState={(values) => this.setState({...values})} 
                  />
                }
              </FormItem>
              <FormItem>
                {getFieldDecorator('methodLabelManual', {
                })(
                  <Checkbox checked={this.state.methodLabelManual} onChange={(e) => this.handleChange(e)}>Pre-Paid Return Label (manual) <Tooltip title="With this option you have to manually send your label to the customer. In the 'Pending' email template you can tell the customer that you will send him/her the label via email after you have reviewed the return. Then send them the label via email manually."><Icon type="info-circle" /></Tooltip></Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('methodCustomerChoice', {
                })(
                  <Checkbox checked={this.state.methodCustomerChoice} onChange={(e) => this.handleChange(e)}>Customer is responsible for Return Shipping</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('methodInStore', {
                })(
                  <Checkbox checked={this.state.methodInStore} onChange={(e) => this.handleChange(e)}>In-Store Return</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          {
            this.context.featureCustomerPhotoUpload
              ? (
                <React.Fragment>
                  <Row type="flex" justify="space-around" align="top">
                    <Col span={22}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                    <Col span={10}>
                      <h4>Customer photo uploads</h4>
                      <span>Let customers upload photos of their return-items. You can review the photos under the <a href="/dashboard">Returns</a> tab by selecting one of the returns.</span>
                    </Col>
                    <Col span={10}>
                      <FormItem>
                        {getFieldDecorator('customerPhotoUploadEnabled', {
                        })(
                          <Checkbox checked={this.state.customerPhotoUploadEnabled} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                        )}
                        <Button onClick={() => this.showConfigPhotoUploadModel()} disabled={!this.state.customerPhotoUploadEnabled}>Configure</Button>
                        {this.state.showConfigPhotoUploadModel && <PhotoUpload showConfigPhotoUploadModel={this.showConfigPhotoUploadModel} state={this.state} updateState={this.updateState}>
                        </PhotoUpload>}
                      </FormItem>
                      <FormItem label="Description text">
                        {getFieldDecorator('customerPhotoUploadDescription', {
                          initialValue: this.state.customerPhotoUploadDescription,
                        })(
                          <TextArea rows={6} placeholder="This description will be displayed to customers alongside the photo upload. Briefly describe what they should pay attention to when uploading photos." />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </React.Fragment>
              )
              : null
          }
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Fees</h4>
              <p><strong>Note:</strong> Any fees set here have to be deducted from the refund. Currently customers cannot be charged during the return process. See <a href="https://support.apphub.com/en/articles/6338339-can-customers-pay-for-their-own-label" target="_blank" rel="noopener noreferrer">here for details</a>.</p>
              {
                this.state.restockingFeeType === "percentage" &&
                <p>Enable checkbox if you want restocking percentage for total amount include tax</p>
              }
              <p>Restocking-Fee: you can set a restocking-fee that is displayed to the customer. This fee has to be deducted from the final refund amount.</p>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('restockingFeeEnabled', {
                })(
                  <Checkbox checked={this.state.restockingFeeEnabled} onChange={(e) => this.handleChange(e)}>Enable restocking-fee</Checkbox>
                )}
              </FormItem>
              <FormItem label="Restocking fee type" >
                {getFieldDecorator('restockingFeeType', {
                  initialValue: this.state.restockingFeeType
                })(
                  <Select
                    disabled={!this.state.restockingFeeEnabled}
                    onChange={this.handleRestockingFeeType.bind(this)}
                  >
                    <Option value="percentage">Percentage</Option>
                    <Option value="fixed">Fixed Amount</Option>
                  </Select>
                )}
              </FormItem>
              {
                this.state.restockingFeeType === "percentage" &&
                <FormItem>
                  {getFieldDecorator('restockingFeeIncludeTax', {
                  })(
                    <Checkbox checked={this.state.restockingFeeIncludeTax} onChange={(e) => this.handleChange(e)} disabled={!this.state.restockingFeeEnabled}>Enable tax based restocking-fee</Checkbox>
                  )}
                </FormItem>
              }
              <FormItem label={`Restocking-Fee ( in ${this.state.restockingFeeType === "fixed" ? " your store's currency" : " percentage"} )`}>
                {getFieldDecorator('restockingFeeValue', {
                  initialValue: this.state.restockingFeeValue,
                })(
                  this.state.restockingFeeType === "fixed" ?
                    <InputNumber disabled={!this.state.restockingFeeEnabled} min={0} step={0.1} />
                    :
                    <InputNumber disabled={!this.state.restockingFeeEnabled} min={0} max={100} step={0.1} />
                )}
                {this.state.restockingFeeType !== "fixed" && " %"}
              </FormItem>
            </Col>
            <Col span={10}>
              {!this.state.methodCustomerPaidLabel &&
                <React.Fragment>
                  <p>Label-Fee: you can set a label-fee to cover costs for the prepaid shipping-label. Only works when you have the prepaid-label option enabled above. This fee has to be deducted from the final refund amount.</p>
                  <p>The currency of your store is automatically applied to the amount set for both fees. Only numbers, DO NOT USE a comma. For example 4.50 is ok - 4,50 is not. DO NOT include the currency in the fields.</p>
                </React.Fragment>
              }
              <p>Enable this option. If you want customer select this option and will be responsible for the pre-paid lebel fee.</p>
            </Col>
            <Col span={10}>
              {!this.state.methodCustomerPaidLabel &&
                <React.Fragment>
                  <FormItem>
                    {getFieldDecorator('labelFeeEnabled', {
                    })(
                      <Checkbox checked={this.state.labelFeeEnabled} onChange={(e) => this.handleChange(e)}>Enable prepaid label-fee</Checkbox>
                    )}
                  </FormItem>
                  <FormItem label="Label-Fee amount (in your store's currency)">
                    {getFieldDecorator('labelFeeValue', {
                      initialValue: this.state.labelFeeValue,
                    })(
                      <InputNumber min={0} step={0.1} />
                    )}
                  </FormItem>
                </React.Fragment>
              }
              <FormItem>
                {getFieldDecorator('methodCustomerPaidLabel', {
                })(
                  <Checkbox
                    checked={this.state.methodCustomerPaidLabel}
                    onChange={(e) => this.handleChange(e)}>
                    Customer is responsible for label fee  &nbsp;
                    <Tooltip title="With this option customer will be responsible for the pre-paid label fees. 
                        If this option is enabled then functionality for the prepiad label and fixed label amount will be disabled."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Allow for Unpaid and Unfulfilled orders</h4>
              <p>Most merchants <strong>do not</strong> need to change these settings.</p>
              <p>Please only make changes if you know how this effects what customers can return.</p>
              <p>Example <strong>Unfulfilled Order</strong>: a merchant wants customers to be able to return an order as a quasi cancellation for their order when the order itself has not shipped, yet. Note that we do not cancel the order, this has to be done manually.</p>
              <p>Example <strong>Pending Payment</strong>: a merchant wants customers to be able to return an order when payment is settled in cash (e.g. courier) and there is a time delay in registering the transaction (manually) so the customer can already initiate a return.</p>
              <p>Example <strong>Authorized Payment</strong>: a merchant wants customers to be able to return an order when payment has been authorized on the customer's end but not captured on the merchant's end. A payment usually goes instantly from authorized to paid in Shopify.</p>
              <p>Example <strong>Multiple Returns</strong>: you offer a lifetime warranty for your products to your customers. A customer has already received an exchange for a faulty item but a year later something else breaks and they need to initiate another Return. This will apply to all items in the order.</p>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('allowOrderUnfulfilled', {
                })(
                  <Checkbox checked={this.state.allowOrderUnfulfilled} onChange={(e) => this.handleChange(e)}>Allow Returns for Unfulfilled Orders</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('allowOrderPaymentPending', {
                })(
                  <Checkbox checked={this.state.allowOrderPaymentPending} onChange={(e) => this.handleChange(e)}>Allow Returns for Pending Payments</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('allowOrderPaymentAuthorized', {
                })(
                  <Checkbox checked={this.state.allowOrderPaymentAuthorized} onChange={(e) => this.handleChange(e)}>Allow Returns for Authorized Payments</Checkbox>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('allowMultipleReturns', {
                })(
                  <Checkbox checked={this.state.allowMultipleReturns} onChange={(e) => this.handleChange(e)}>Allow multiple Returns</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          {
            this.context.featureMultichannel
              ? (
                <React.Fragment>
                  <Row type="flex" justify="center" align="middle" style={{ marginBottom: 20 }}>
                    <Col>
                      <h4>Manual Multichannel RMAs</h4>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                    <Col span={10}>
                      <h4>Vendors</h4>
                      <span>Add returns from vendors outside of Shopify. Enter as many vendors as you like. One vendor per line (no comma inbetween).</span>
                    </Col>
                    <Col span={10}>
                      <FormItem>
                        {
                          getFieldDecorator('vendorList', {
                            initialValue: this.state.vendorList,
                          })(
                            <TextArea rows={6} placeholder="Enter 1 vendor per line (no comma inbetween)" />
                          )
                        }
                      </FormItem>
                    </Col>
                  </Row>
                </React.Fragment>
              )
              : null
          }

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Return address</h4>
              <span>Enter the address that is shown to customers in preparation for sending back their package. This address will also be used if you have pre-paid return-labels enabled above.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('receiverCountry', {
                  initialValue: this.state.receiverCountry,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Select
                    placeholder="Select country"
                  >
                    {this.getCountriesList()}
                  </Select>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverName', {
                  initialValue: this.state.receiverName,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="Full name" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverCompany', {
                  initialValue: this.state.receiverCompany,
                })(
                  <Input addonBefore="Company name" placeholder="(Optional)" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverStreet1', {
                  initialValue: this.state.receiverStreet1,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="Street address" placeholder="Street and number, P.O. box, c/o." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverStreet2', {
                  initialValue: this.state.receiverStreet2,
                })(
                  <Input addonBefore="(optional)" placeholder="Apartment, suite, unit, building, floor, etc." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverCity', {
                  initialValue: this.state.receiverCity,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="City" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverState', {
                  initialValue: this.state.receiverState,
                  rules: [
                    { validator: this.handleStateValidator }
                  ]
                })(
                  <Input addonBefore="State" placeholder="State / Province / Region" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverZip', {
                  initialValue: this.state.receiverZip,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="Zip Code" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverPhone', {
                  initialValue: this.state.receiverPhone,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="Phone number" placeholder="May be used to assist delivery" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('receiverEmail', {
                  initialValue: this.state.receiverEmail,
                  rules: [
                    { required: true }
                  ]
                })(
                  <Input addonBefore="E-Mail" placeholder="May be used to assist delivery" />
                )}
              </FormItem>
            </Col>
          </Row>
          {this.context.featureMultipleAddress &&
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle" style={{ marginBottom: 20 }}>
                <Col>
                  <h4>Multiple Address</h4>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Store Address</h4>
                  <span>You can add multiple store addresses. Selected Store address that is shown to customers in preparation for sending back their package. This address will also be used if you have pre-paid return-labels enabled above</span>
                </Col>
                <Col span={10}>
                  <ReturnMultipleAddress
                    countriesList={this.getCountriesList()}
                    handleChangeMultipleAddress={this.handleChangeMultipleAddress}
                    defaultActiveStoreAddress={this.state.selectedStoreAddress}
                    showAddressModal={this.showAddressModal}
                    visible={this.state.visible}
                    getReturnSettings={this.getReturnSettings}
                    updateProps={this.state.updateProps}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" align="middle" style={{ marginBottom: 20 }}>
                {
                  this.context.featureMultipleAddressQuota > 0
                    ? (
                      <Text>You have {this.context.featureMultipleAddressQuota} store addresses left on your current plan. </Text>
                    )
                    : (
                      <p><Text>You have {this.context.featureMultipleAddressQuota} store addresses left on your current plan. Upgrade your plan.</Text></p>
                    )
                }
              </Row>
              <Row type="flex" justify="center" align="middle" style={{ marginBottom: 20 }}>
                <Col>
                  <Button type="primary"
                    onClick={this.showAddressModal}
                    style={{ marginTop: '5%' }}
                    disabled={this.context.featureMultipleAddressQuota === 0 ? true : false}
                  >
                    Add Multiple Addresses
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          }
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedSettings = Form.create()(Settings);
export default WrappedSettings;

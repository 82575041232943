/**
 * Component to manage our Return Query
 *
 * @component
 */

import React, { Component } from 'react'
import {
  Modal,
  Button,
  Divider,
  Col,
  Row,
  Checkbox,
  Input,
  Select,
  message,
  Icon,
  InputNumber
} from 'antd'
import axios from 'axios'
const { Option } = Select

const TYPE_OF_CUSTOMIZE_QUERY = [
  {
    label: 'Note',
    value: 'note',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Radio-button',
    value: 'radio-button',
  },
]

const defaultCustomizeQuery = {
  title: null,
  isRequire: false,
  type: 'note', // note | checkbox | radio-button
  options: [],
}

class ReturnCusomizeQuery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      additionalFields: props?.additionalFields || [],
      position: props?.position, // position customizeQuery in additionalFields
      customizeQuery:
        props?.position !== null && props?.position !== undefined
          ? props.additionalFields[props.position]
          : defaultCustomizeQuery,
      isEditModel: props?.position !== null && props?.position !== undefined ? true : false,
      isLoading: false,
    }
  }

  onChangeRequire = (e) => {
    const customizeQuery = {
      ...this.state.customizeQuery,
      isRequire: e.target.checked,
    }
    this.setState({
      customizeQuery: customizeQuery,
    })
  }

  onChangeTitle = (e) => {
    const customizeQuery = {
      ...this.state.customizeQuery,
      title: e.target.value,
    }
    this.setState({
      customizeQuery: customizeQuery,
    })
  }

  onUpdateType = (e) => {
    const oldType = this.state.customizeQuery?.type
    const customizeQuery = {
      ...this.state.customizeQuery,
      type: e,
    }
    if (e === 'checkbox' || e === 'radio-button') {
      if (e !== oldType) {
        customizeQuery.options = []
      }
      if (customizeQuery.options.length === 0) {
        customizeQuery.options.push('')
      }
    }
    this.setState({
      customizeQuery: customizeQuery,
    })
  }
  handleAddOption = () => {
    const customizeQuery = this.state.customizeQuery
    if (customizeQuery.options.length < 3) {
      customizeQuery.options.push('')
      this.setState({
        customizeQuery: customizeQuery,
      })
    }
  }
  handleMinusOption = () => {
    const customizeQuery = this.state.customizeQuery
    if (customizeQuery.options.length > 1) {
      customizeQuery.options.pop()
      this.setState({
        customizeQuery: customizeQuery,
      })
    }
  }
  handleChangeOptions = (e) => {
    const customizeQuery = this.state.customizeQuery
    customizeQuery.options[Number(e.target.id)] = e.target.value
    this.setState({
      customizeQuery: customizeQuery,
    })
  }

  onSave = async () => {
    this.setState({
      isLoading: true,
    });
    const additionalFields = this.state.additionalFields
    const customizeQuery = this.state.customizeQuery
    // validate title
    if (!customizeQuery?.title || customizeQuery.title.length < 1) {
      this.setState({
        isLoading: false,
      });
      return message.error('Please fill in your query', 5)
    }
    // validate option
    if (['checkbox', 'radio-button'].includes(customizeQuery.type)) {
      const option = customizeQuery?.options.filter((i) => i !== '')
      if (option.length === 0) {
        this.setState({
          isLoading: false,
        });
        return message.error('Please add your options before saving', 5)
      }
      if (option.length < customizeQuery.options.length) {
        this.setState({
          isLoading: false,
        });
        return message.error(
          'Please remove your blank options before saving',
          5
        )
      }
    }
    if (this.state.position === null || this.state.position === undefined) {
      //create new customizeQuery
      additionalFields.push(customizeQuery)
    } else {
      //update position
      additionalFields[this.state.position] = customizeQuery
    }
    const payload = {
      additionalFields: this.state.additionalFields,
    }
    try {
      await axios.post(`/api/returnSettings/updateCustomizeQuery`, payload)
      message.success('Saved successfully', 5)
      this.setState({
        isLoading: false,
      });
      //update props in page order
      this.props.onCancel()
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      return message.error('Something went wrong please try again', 5)
    }
  }

  onDelete = async () => {
    const additionalFields = this.state.additionalFields
    // remove current field
    additionalFields.splice(this.state.position, 1)
    const payload = {
      additionalFields: this.state.additionalFields,
    }
    try {
      await axios.post(`/api/returnSettings/updateCustomizeQuery`, payload)
      message.success('Deleted successfully', 5)
      this.props.onCancel()
      //update props in page order
    } catch (e) {
      return message.error('Something went wrong please try again', 5)
    }
  }

  componentDidMount = async () => {
    // this.getReturnSetting()
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          width={this.props.featureMultilangReturnReasons ? 1100 : 720}
          title="Customize customer note"
          visible={this.props.visible}
          // onOk={this.props.onOk}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: "center", paddingTop: '10px' }}>
            <Col span={4}>
              <span>Your query here</span>
            </Col>
            <Col span={20}>
              <Input
                span={24}
                value={this.state?.customizeQuery?.title}
                onChange={this.onChangeTitle}
                placeholder="Example: Please select all that apply down below in terms of your satisfaction"
              />
            </Col>
          </Row>
          <Row span={24} style={{ paddingTop: '10px' }}>
            <Col span={4} style={{ paddingTop: '5px' }}>
              <p>Type of query</p>
            </Col>
            <Col span={5}>
              <Select
                onChange={this.onUpdateType}
                style={{ width: '100%' }}
                defaultValue={this.state.customizeQuery.type}
                value={this.state.customizeQuery.type}
              >
                {TYPE_OF_CUSTOMIZE_QUERY.map((i, key) => (
                  <Option key={key} value={i.value}>
                    {i.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={4} style={{ padding: '5px 20px'}}>
              <p>Required?</p>
            </Col>
            <Col span={1} style={{ paddingTop: '5px'}}>
              <Checkbox
                checked={this.state?.customizeQuery?.isRequire}
                onChange={this.onChangeRequire}
                defaultValue={false}
              ></Checkbox>
            </Col>
          </Row>
          {['checkbox', 'radio-button'].includes(
            this.state.customizeQuery.type
          ) && (
            <React.Fragment>
              <Row
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                {this.state.customizeQuery.type === 'checkbox'
                  ? `Checkboxes (up to 3)`
                  : `Radio buttions (up to 3)`}
                :{' '}
                <Icon type="plus-circle" style={{ fontSize: "20px", padding: "2px" }} onClick={this.handleAddOption} />
                <Icon type="minus-circle" style={{ fontSize: "20px", padding: "2px" }} onClick={this.handleMinusOption} />
              </Row>
              <Row span={24}>
                {this.state.customizeQuery?.options &&
                  this.state.customizeQuery?.options.map((i, key) => (
                    <Col span={8} key={key} style={{ padding: '3px' }}>
                      <Row>Option {key + 1} </Row>
                      <Row>
                        <Input
                          span={22}
                          id={key.toString()}
                          onChange={this.handleChangeOptions}
                          defaultValue={i}
                        />
                      </Row>
                    </Col>
                  ))}
              </Row>
            </React.Fragment>
          )}
          <Row
            span={24}
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingTop: '100px',
            }}
          >
            <Button type="primary" onClick={this.onSave} loading={this.state.isLoading}>
              Save
            </Button>
            <Button
              type={this.state.isEditModel ? "danger" : "secondary"}
              style={{ marginLeft: 10 }}
              onClick={this.state.isEditModel ? this.onDelete : this.props?.onCancel}
            >
              {this.state.isEditModel ? "Delete" : "Cancel"}
            </Button>
          </Row>
          <Divider />
        </Modal>
      </React.Fragment>
    )
  }
}

export default ReturnCusomizeQuery

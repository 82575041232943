/**
 * Component for overview settings page of our Exchanges in the Dashboard.
 *
 * @component
 */

import React from 'react';
import { Tabs, Tag } from 'antd';
import AdvancedExchanges from './AdvancedExchanges';
import VariantExchanges from './VariantExchanges';
import ExchangeOrders from './ExchangeOrders';
import StoreWideExchanges from './StoreWideExchange';
import Page from '../../../../components/Page';
const { TabPane } = Tabs;

class ExchangeSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  callback = (key) => {
    // eslint-disable-next-line no-console
    console.log(key);
  };

  render() {
    return (
      <Tabs onChange={this.callback.bind(this)} type="card">
        <TabPane tab="Variant Exchanges" key="1">
          <Page component={VariantExchanges} title="Variant Exchanges" />
        </TabPane>
        <TabPane
          tab={
            <span>
              Advanced Exchanges <Tag color="gold">new</Tag>
            </span>
          }
          key="2"
        >
          <Page component={AdvancedExchanges} title="Advanced Exchanges" />
        </TabPane>
        <TabPane tab="Exchange Orders" key="3">
          <Page component={ExchangeOrders} title="Exchange Orders" />
        </TabPane>
        <TabPane
          tab={
            <span>
              Store-Wide Exchanges <Tag color="gold">new</Tag>
            </span>
          }
          key="4"
        >
          <Page component={StoreWideExchanges} title="Store-Wide Exchanges" />
        </TabPane>
      </Tabs>
    );
  }
}

export default ExchangeSettings;

/**
 * Component for customer E-Mail settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Button, Form, Input, Checkbox, Row, Col, Select, Divider, Collapse, Tag, Tooltip, Icon, message, Upload, Avatar, Popconfirm, Modal, Alert } from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import { allLanguages } from "../../../../../src/constants/constants";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { trackFSEvent } from "../../../../helpers/fullstory";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;
const { Panel } = Collapse;

const initialState = {
  emailPendingActive: true,
  emailPendingTitle: "",
  emailPendingContent: "",
  emailApprovedActive: true,
  emailApprovedTitle: "",
  emailApprovedContent: "",
  emailApprovedLabelActive: false,
  emailApprovedLabelTitle: "",
  emailApprovedLabelContent: "",
  emailApprovedLabelErrorActive: false,
  emailApprovedLabelErrorTitle: "",
  emailApprovedLabelErrorContent: "",
  emailApprovedLabelManualActive: false,
  emailApprovedLabelManualTitle: "",
  emailApprovedLabelManualContent: "",
  emailShippedActive: true,
  emailShippedTitle: "",
  emailShippedContent: "",
  emailReceivedActive: true,
  emailReceivedTitle: "",
  emailReceivedContent: "",
  emailResolvedActive: true,
  emailResolvedTitle: "",
  emailResolvedContent: "",
  emailRejectedActive: true,
  emailRejectedTitle: "",
  emailRejectedContent: "",
  emailStoreCreditActive: true,
  emailStoreCreditTitle: "",
  emailStoreCreditContent: "",
  defaultLocale: false,
  emailPendingContentHtml: "",
  emailApprovedContentHtml: "",
  emailShippedContentHtml: "",
  emailReceivedContentHtml: "",
  emailResolvedContentHtml: "",
  emailRejectedContentHtml: "",
  emailApprovedLabelContentHtml: "",
  emailApprovedLabelErrorContentHtml: "",
  emailApprovedLabelManualContentHtml: "",
  emailStoreCreditContentHtml: "",
  emailInTransitActive: true,
  emailInTransitTitle: "",
  emailInTransitContent: "",
  emailInTransitContentHtml: "",
};

class Email extends React.Component {

  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
 
    this.state = {
      ...initialState,
      locale: "en",
      emailFromAddress: "",
      availableLanguages: [],
      selectedTemplate : "Pending",
      imageUrl: "",
      visible: false,
      receivingEmail: "",
      enableHtmlEmailTemplates: false,
      emailLinkToButtonConversion: false,
      emailDefaultContent: {},      
    }
  }

  componentDidMount = async () => {
    try {
      const response = await axios.get("/api/emailSettings");
      if (response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 4);
      }
      this.setState({
        enableHtmlEmailTemplates: response.data.enableHtmlEmailTemplates
      });
      let emailData = response.data.emailSettings;
      let availableLanguages = [];
      emailData.map((email) => {
        availableLanguages.push(email.locale)
        if(email.defaultLocale === true) {
          emailData = email
        }
      })
      const emailDefaultTemplate = response.data.emailDefault.reduce(
        (data, i) => {
          data[i.locale] = i;
          return data;
        },
        {},
      );
      return this.setState({
        ...emailData,
        availableLanguages: availableLanguages,
        emailDefaultContent: emailDefaultTemplate,
      });
    } catch(err) {
      return message.error("There was an error loading your email templates. Try again.", 4);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields( async (err, values) => {
      if (err) {
        return message.error("Error saving your email templates. Try again.", 4);
      }
      if (this.state.enableHtmlEmailTemplates) {
        let emailContent = this.state[`email${this.state.selectedTemplate}ContentHtml`];
        if (emailContent === "<p><br></p>") {
          return message.error("Email content can't be empty. Try again.", 4);
        }
        values = { ...values, [`email${this.state.selectedTemplate}ContentHtml`]: emailContent };
      }
      try {
        const response = await axios.post("/api/emailSettings/update", values);
        if (response.data.status === "error") {
          const errorMessage = response.data.error;
          return message.error(errorMessage, 4);
        }
        trackFSEvent("Edit Customer Notification", {
          feature: "Email settings"
        });
        message.success("E-Mail settings updated successfully.", 4);
        let availableLanguages = this.state.availableLanguages;
        if(!this.state.availableLanguages.includes(response.data.locale)) {
          availableLanguages.push(response.data.locale)
        }
        this.props.form.resetFields()
        return this.setState({
          ...response.data,
        });
      } catch(err) {
        return message.error("There was an error saving your email templates. Try again.", 4);
      }
    });
  }

  handleChange = (value) => {
    this.setState({
      selectedTemplate: value,
    });
  }

  handleLanguageChange = async(value) => {
    this.setState({
      locale: value,
    });
    try {
      const response = await axios.get("/api/emailSettings")
      if (response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 4);
      }

      let emailData = response.data.emailSettings.filter((email) => {
        if(email.locale === value) {
          return email
        }
      })
      if(emailData.length > 0) {
        this.setState ({
          ...emailData[0]
        })
      }
      else {
        this.setState ({
          ...initialState
        })
      }
      this.props.form.resetFields();

    } catch(err) {
      return message.error("There was an error changing your email templates. Try again.", 4);
    }
  }
  onChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  }
  onChangeAttachment = (e) => {
    this.setState({
      emailLinkToButtonConversion: e.target.checked,
    });
  }
  onChangeInput = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.value,
    });
  }

  onChangeText = (target, text) => {
    this.setState({
      [target]: text,
    });
  };


  async checkEmailImage(file) {
    const allowedFileTypes = ["image/png", "image/jpeg", "image/svg+xml"]
    if (!allowedFileTypes.includes(file.type)) {
      message.error("Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.", 5);
      return false;
    }
    if ((file.size / 1024 / 1024) > 2) {
      message.error("Only files smaller than 2 MB allowed.", 5);
      return false;
    }
    return true;
  }

  async handleImageUpload(options) {
    options.onProgress({ percent: 20 });
    let signedUrl = "";
    let bucketUrl = "";
    let fileName = "";
    try {

      let response = await axios.post("/api/emailSettings/image/upload", { fileType: options.file.type, fileSize: options.file.size / 1024 / 1024 });
      if (response.data.error === "wrong-size") {
        return message.error("Only files smaller than 2 MB allowed.", 5);
      }
      if (response.data.error === "wrong-format") {
        return message.error("Only .jpg, .jpeg, .png and .svg files smaller than 1 MB allowed.", 5);
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch (err) {
      console.log("err",err)
      return message.error("Error preparing upload. Try again.", 4);
    }

    try {
      // eslint-disable-next-line no-unused-vars
      let fileUpload = await axios.put(signedUrl, options.file, { headers: { 'Content-Type': options.file.type } });
      options.onProgress({ percent: 75 });
      let saveUrl = await axios.post("/api/emailSettings/image/update", { imageUrl: bucketUrl });
      if (saveUrl.data.error) {
        return message.error("Error saving image. Try again.", 4);
      }
      message.success("Image uploaded successfully.", 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        imageUrl: bucketUrl,
        ...saveUrl.data,
      });
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error("Error saving inage. Try again.", 4);
    }
  }

  async handleDeleteImage() {
    try {
      const response = await axios.post("/api/emailSettings/image/delete", { locale: this.state.locale });
      if (response.data.error) {
        return message.error("Error deleting current logo. Try again.", 4);
      }
      message.success("Current image deleted successfully", 4);
      const stateData = response.data;
      this.setState({
        ...stateData,
      });
    } catch (err) {
      message.error("Error deleting current image. Try again.", 4);
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  sendEmail = async () =>  {
    try {
      const email = this.state.receivingEmail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const test = emailRegex.test(email);
      if(!test) {
        return message.error("Please enter a valid email and try again.", 4);
      }
      const content = {
        emailTitle: this.state[`email${this.state.selectedTemplate}Title`],
        emailContent: this.state[`email${this.state.selectedTemplate}ContentHtml`]
      }
      const response = await axios.post("/api/emailSettings/sendTestEmail", { 
        email,
        locale: this.state.locale,
        content,
        imageUrl: this.state.imageUrl,
        emailLinkToButtonConversion: this.state.emailLinkToButtonConversion
      });
      if(response.status != 200) {
        return message.error("Something went wrong while sending email, Please try again later.", 5);
      }
      this.setState({
        visible: false,
      });
      return message.success("Email sent successfully!", 5)
    } catch (err) {
      message.error("Failed to send Email. Try again.", 4);
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  onChangeEmail = (e) => {
    this.setState({
      receivingEmail: e.target.value,
    });
  }

  render() {
    const existingLanguages = allLanguages.map( (language) => {
      if(this.state.availableLanguages.includes(language.code))
        return <Option value={language.code} key={language.code}>{language.name}</Option>;
    });
    const remainingLanguages = allLanguages.map( (language) => {
      if(!this.state.availableLanguages.includes(language.code))
        return <Option value={language.code} key={language.code}>{language.name}</Option>;
    });
    const defaultTitle = this.state.emailDefaultContent[this.state.locale] ? this.state.emailDefaultContent[this.state.locale][`email${this.state.selectedTemplate}Title`] : "";
    const defaultContent = this.state.emailDefaultContent[this.state.locale] ? this.state.emailDefaultContent[this.state.locale][`email${this.state.selectedTemplate}Content`] : "";
    const defaultContentHtml = this.state.emailDefaultContent[this.state.locale] ? this.state.emailDefaultContent[this.state.locale][`email${this.state.selectedTemplate}ContentHtml`] : "";
    const valueContentHtml = this.state[`email${this.state.selectedTemplate}ContentHtml`] && this.state[`email${this.state.selectedTemplate}ContentHtml`].length > 0 ? this.state[`email${this.state.selectedTemplate}ContentHtml`] : defaultContentHtml;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const statusDescription = {
      Pending: <React.Fragment>An email for a <strong>Pending Return</strong> is sent out to customers when they initially submit a return through the return-portal and wait for your approval.</React.Fragment>,
      Approved: <React.Fragment>An email for an <strong>Approved Return</strong> is sent out to customers once you approve the return in the dashboard (or auto-approved) and no prepaid label is enabled for this return. Make sure to <strong>include a return address</strong> (with country if you have international customers) and precise instructions for your customers.</React.Fragment>,
      ApprovedLabel: <React.Fragment>Email for an <strong>Approved Return</strong> that is sent out to customers instead of the regular Approved-Email once you approve the return in the dashboard (or auto-approved). This email will trigger if an automatic return label is present.</React.Fragment>,
      ApprovedLabelError: <React.Fragment>Email that is sent out when there is an issue creating the automatic label (e.g. address errors, etc). You want to tell the customer that you'll get back to them with a label asap to avoid additional support inquiries.</React.Fragment>,
      ApprovedLabelManual: <React.Fragment>Email for an <strong>Approved Return</strong> that is sent out to the customer instead of the regular Approved-Email once you approve the return in the dashboard. This email will trigger if a manually uploaded return label is present.</React.Fragment>,
      Shipped: <React.Fragment>An email for a <strong>Shipped Return</strong> is sent out to customers when the status of the return is set to shipped by either you or the customer.</React.Fragment>,
      InTransit: <React.Fragment>An email for an <strong>In Transit Return</strong> is sent out to customers when the status of the return is set to in transit by either you or the customer.</React.Fragment>,
      Received: <React.Fragment>An email for a <strong>Received Return</strong> is sent out to customers once you received the package or product.</React.Fragment>,
      Resolved: <React.Fragment>An email for a <strong>Resolved Return</strong> is sent out to customers once you issued a refund or exchange.</React.Fragment>,
      Rejected: <React.Fragment>An email for a <strong>Rejected Return</strong> is sent out to customers when you reject their return inquiry. You can include default reasons for a rejection in this email (like hygiene, out of refund period, final sale, etc). Reach out to the customer directly if you want to explain something you can't in this template.</React.Fragment>,
      StoreCredit: <React.Fragment>An email when <strong>Store Credit</strong> is issued during the refund process. This email contains the code of the gift-card or discount-code for the customer to redeem their store-credit.</React.Fragment>
    }
    const enableLoadEmailTemplate = !!this.state.emailDefaultContent[this.state.locale];

    return(
      <React.Fragment>
        <Row type="flex" justify="start" align="top" style={{ paddingBottom: 35 }}>
          <Col>
            <h1 className="pageTitle">Customer notifications</h1>
            <p>Customize your email templates and tailor the communication with your customers to your brand.</p>
          </Col>
        </Row>

        <Form onSubmit={ this.handleSubmit } hideRequiredMark={true}>
          {
            (this.state.enableHtmlEmailTemplates) &&
            <React.Fragment>
              <Row type="flex" justify="center" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Upload Image for email</h4>
                  <span>If you want to show a image to your emails, upload it here.</span>
                </Col>
                <Col span={6}>
                  {(this.state.imageUrl === "" || this.state.imageUrl === null)
                    ? (<Upload beforeUpload={(file) => { return this.checkEmailImage(file) }} customRequest={(options) => { this.handleImageUpload(options) }}><Button><Icon type="upload" />Upload email image</Button></Upload>)
                    : (
                      <React.Fragment>
                        <Row type="flex" justify="center">
                          <img src={this.state.imageUrl} style={{ maxHeight: 100, maxWidth: 200 }} />
                        </Row><br />
                        <Avatar style={{ backgroundColor: '#52c41a' }} icon="check" />
                        <Divider type="vertical" />

                        <span>Email Image Uploaded</span>
                        <Divider type="vertical" />
                        <Popconfirm title="Do you really want to delete the current email image? You can upload a new one later." onConfirm={() => { this.handleDeleteImage() }} okText="Yes" cancelText="No">
                          <a href="#">Delete?</a>
                        </Popconfirm>
                      </React.Fragment>
                    )
                  }
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
            </React.Fragment>
          }
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="E-Mail"
              >
                {getFieldDecorator('emailFromAddress', {
                  initialValue: this.state.emailFromAddress,
                  rules: [{ required: true, message: 'Please enter a valid email-address.' }],
                })(
                  <Input style={{ width: 300 }} placeholder="Email your customers can reply to" />
                )}
                <span> (usually your customer support email - set once for all emails)</span>
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="Select Language"
              >
                {getFieldDecorator(`locale`, {
                  initialValue: this.state.locale,
                  setFieldsValue: this.state.locale,
                })(
                  <Select style={{ width: 400 }} onChange={this.handleLanguageChange.bind(this)} placeholder="Select language">
                    <Option key="disabledExisting" disabled={true}>--- Existing Languages</Option>
                    { existingLanguages }
                    <Option key="disabledRemaining" disabled={true}>--- Available Languages</Option>
                    { remainingLanguages }

                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="Active"
              >
                {getFieldDecorator(`defaultLocale`, {
                })(
                  <Checkbox
                    checked={this.state.defaultLocale}
                    onChange={this.onChange.bind(this)}
                  >
                    Default Language
                  </Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              {
                this.state.selectedTemplate === "InTransit" 
                  ? <>
                    <Alert
                      message="Important"
                      description={
                        <span>
                          To ensure that your customers receive tracking information, please remember to include <strong>&#123;LABEL_TRACKING_LINK&#125;</strong> in your email templates if you are using prepaid labels.
                        </span>
                      }
                      type="info"
                      showIcon
                    />
                    <br></br>
                  </>
                  : null
              }
              <FormItem
                {...formItemLayout}
                label="Select email"
              >
                {getFieldDecorator(`${this.state.selectedTemplate}`, {
                  initialValue: this.state.selectedTemplate,
                  setFieldsValue: this.state.selectedTemplate,
                })(
                  <Select style={{ width: 400 }} onChange={this.handleChange.bind(this)} placeholder="Select template">
                    <Option value="Pending">Pending Return</Option>
                    <Option value="Approved">Approved Return</Option>
                    <Option value="ApprovedLabel">Approved Return (Automatic Prepaid Label)</Option>
                    <Option value="ApprovedLabelError">Approved Return (Automatic Prepaid Label - Error)</Option>
                    <Option value="ApprovedLabelManual">Approved Return (Manually Uploaded Prepaid Label)</Option>
                    <Option value="Shipped">Shipped Return</Option>
                    <Option value="InTransit">In Transit Return</Option>
                    <Option value="Received">Received Return</Option>
                    <Option value="Resolved">Resolved Return</Option>
                    <Option value="Rejected">Rejected Return</Option>
                    {
                      this.context.featureStoreCredit
                        ? <Option value="StoreCredit">Store-Credit</Option>
                        : null
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="Purpose"
              >
                <div>
                  { statusDescription[this.state.selectedTemplate] }
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="Personalization"
              >
                <div>
                  <Collapse>
                    <Panel header="Want to personalize your emails with variables?" key="1">
                      <p>
                      You can use variables to dynamically personalize your emails.
                      </p>
                      <p>
                        <strong>IMPORTANT: copy the variables you want to use including the curly-braces to your email. They need to match exactly.</strong>
                      </p>
                      <p>
                        <p>The following variables are available:</p>
                        <p><strong>Order related</strong></p>
                        <p><Tag>{"{RMA_NUMBER}"}</Tag> the number of the return (also called RMA number)</p>
                        <p><Tag>{"{ORDER_NAME}"}</Tag> the order-name/number on Shopify, e.g. #1234</p>
                        <p><Tag>{"{ORDER_ID}"}</Tag> the orderId on Shopify, e.g. 123456</p>
                        <p><Tag>{"{ITEM_DETAILS}"}</Tag> list of items with reason for return</p>
                        <p><strong>Business related</strong></p>
                        <p><Tag>{"{RETURN_ADDRESS}"}</Tag> the general return address you have set in the Return-Settings (without the phone number) or a custom address when using our rules-feature</p>
                        <p><strong>Customer related</strong></p>
                        <p><Tag>{"{CUSTOMER_EMAIL}"}</Tag> the email address the customer used for the order</p>
                        <p><Tag>{"{CUSTOMER_FIRST_NAME}"}</Tag> the first name of the customer</p>
                        <p><Tag>{"{CUSTOMER_LAST_NAME}"}</Tag> the last name of the customer</p>
                        <p><strong>Customer shipping address related</strong></p>
                        <p><Tag>{"{CUSTOMER_ADDRESS}"}</Tag> the address of the customer</p>
                        <p><Tag>{"{CUSTOMER_APARTMENT_SUIT}"}</Tag> the apartment, suit, etc of the customer</p>
                        <p><Tag>{"{CUSTOMER_CITY}"}</Tag> the city of the customer</p>
                        <p><Tag>{"{CUSTOMER_STATE}"}</Tag> the state of the customer</p>                        
                        <p><Tag>{"{CUSTOMER_ZIP_CODE}"}</Tag> the ZIP code of the customer</p>   
                        <p><Tag>{"{CUSTOMER_COUNTRY}"}</Tag> the country of the customer</p>
                        <p><strong>Shipping-label related</strong></p>
                        <p><Tag>{"{LABEL_DOWNLOAD_LINK}"}</Tag> the download link to the PDF prepaid label, only available if you have automatic prepaid labels enabled</p>
                        <p><Tag>{"{LABEL_QR_CODE_DOWNLOAD_LINK}"}</Tag> the download link to the QR Code label. Only available for USPS on EasyPost and if you have this feature available on your plan</p>
                        <p><Tag>{"{LABEL_TRACKING_LINK}"}</Tag> the tracking link for the prepaid label, only available if you have automatic prepaid labels enabled</p>
                        <p><Tag>{"{COMMERCIAL_INVOICE}"}</Tag> the download link to the commercial invoice for international shipments (if available)</p>
                        <p><Tag>{"{MANUAL_LABEL_DOWNLOAD_LINK}"}</Tag> the download link to the label, only available if you uploaded the prepaid-label for the return</p>
                        <p><Tag>{"{MANUAL_LABEL_SECOND_DOWNLOAD_LINK}"}</Tag> the download link to the second document/label, only available if you uploaded the file in the dashboard</p>
                        {
                          this.context.featureStoreCredit
                            ? (
                              <React.Fragment>
                                <p><strong>Refund related</strong></p>
                                <p><Tag>{"{STORE_CREDIT_CODE}"}</Tag> The code of the gift-card (only Shopify Plus) or discount-code for the customer to redeem their store-credit.</p>
                                <p><Tag>{"{STORE_CREDIT_AMOUNT}"}</Tag> The amount of store-credit in the form of gift-card (only Shopify Plus) or discount-code.</p>
                                <p><Tag>{"{STORE_CREDIT_CURRENCY}"}</Tag> The currency of store-credit including in the form of gift-card (only Shopify Plus) or discount-code.</p>
                              </React.Fragment>
                            )
                            : null
                        }
                      </p>
                    </Panel>
                  </Collapse>
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="Active"
              >
                {getFieldDecorator(`email${this.state.selectedTemplate}Active`, {
                })(
                  <Checkbox
                    checked={this.state[`email${this.state.selectedTemplate}Active`]}
                    onChange={this.onChange.bind(this)}
                  >
                    Send this email to customer
                  </Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>          
          {
            (this.state.enableHtmlEmailTemplates) &&
            <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{marginBottom: "5%"}}>
              <Col span={24}>
                <FormItem
                  {...formItemLayout}
                  label="Attachment"
                >
                  {getFieldDecorator('emailLinkToButtonConversion', {})(
                    <Checkbox
                      checked={this.state.emailLinkToButtonConversion}
                      onChange={(e) => this.onChangeAttachment(e)}
                    >
                      Check this box if you want to convert your links in the form of buttons in the email.
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
              <Col span={20} offset={4}>
                <Collapse>
                  <Panel header="By checking the box, the following variables are going to be displayed as buttons (if applicaple):" key="1">
                    <p>
                      <p><strong>Shipping-label related</strong></p>
                      <p><Tag>{"{LABEL_DOWNLOAD_LINK}"}</Tag> the download link to the PDF prepaid label, only available if you have automatic prepaid labels enabled</p>
                      <p><Tag>{"{LABEL_QR_CODE_DOWNLOAD_LINK}"}</Tag> the download link to the QR Code label. Only available for USPS on EasyPost and if you have this feature available on your plan</p>
                      <p><Tag>{"{LABEL_TRACKING_LINK}"}</Tag> the tracking link for the prepaid label, only available if you have automatic prepaid labels enabled</p>
                      <p><Tag>{"{COMMERCIAL_INVOICE}"}</Tag> the download link to the commercial invoice for international shipments (if available)</p>
                      <p><Tag>{"{MANUAL_LABEL_DOWNLOAD_LINK}"}</Tag> the download link to the label, only available if you uploaded the prepaid-label for the return</p>
                      <p><Tag>{"{MANUAL_LABEL_SECOND_DOWNLOAD_LINK}"}</Tag> the download link to the second document/label, only available if you uploaded the file in the dashboard</p>
                    </p>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          }
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label="E-Mail subject"
              >
                {getFieldDecorator(`email${this.state.selectedTemplate}Title`, {
                  initialValue: this.state[`email${this.state.selectedTemplate}Title`] || defaultTitle,
                  rules: [{ required: true, message: 'Please enter a subject for this email.' }],
                })(
                  <Input onChange={this.onChangeInput.bind(this)} placeholder="Subject line (title) of your email." />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem
                {...formItemLayout}
                label={
                  <Tooltip
                    placement="bottom"
                    title={(this.state.enableHtmlEmailTemplates) ?
                      "Great news! Our support now extends to text, Emojis, URLs, and HTML elements. Feel free to utilize all of these in your content!"
                      :
                      "We currently support text, Emojis and URLs. Do not add HTML elements."
                    }
                  >
                    <span>E-Mail content</span> <Icon type="info-circle" />
                  </Tooltip>}
              >
                {
                  (this.state.enableHtmlEmailTemplates) ?
                    <ReactQuill theme="snow" value={valueContentHtml} onChange={(value) => {this.onChangeText(`email${this.state.selectedTemplate}ContentHtml`, value)}}/>
                    :
                    getFieldDecorator(`email${this.state.selectedTemplate}Content`, {
                      initialValue: this.state[`email${this.state.selectedTemplate}Content`] || defaultContent,
                      rules: [{ required: true, message: 'Please enter content for this email.' }],
                    })(
                      <TextArea onChange={this.onChangeInput.bind(this)} placeholder="Content of your email." autoSize={{ minRows: 4 }} />
                    )
                }
              </FormItem>
            </Col>
          </Row>

          {
            (this.state.enableHtmlEmailTemplates) &&
            <Row>
              <Col>
                <React.Fragment>
                  <Button type="primary" onClick={this.showModal} style={{float:"right"}}>
                    Send test email
                  </Button>
                  <Modal
                    title="Send Test Email"
                    visible={this.state.visible}
                    onOk={this.sendEmail}
                    onCancel={this.handleCancel}
                  >
                    <h3>Please enter receiving email </h3>
                    <Input type="email" placeholder="Please enter email" onChange={(value) => this.onChangeEmail(value)} />
                  </Modal>
                </React.Fragment>
              </Col>
            </Row>
          }

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedEmail = Form.create()(Email);
export default WrappedEmail;
